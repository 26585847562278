import { Theme } from '@mui/material';
import { DataGridPro, DataGridProProps, GridColDef, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid-pro';
import { memoize, omit } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import getSummaryStyles from './summary-table-styles';

import { useBudgetStateContext } from '@/forms/contexts/budget-context';
import { CostEntity } from '@/types';
import { costingGridColumnDefinition, flattenRows, formatValueCell } from '@forms/utils';

const EXCLUDED_KEYS = ['Children', 'Type', 'FunderTag', 'FEC Recovery %', 'UnOverriddenFunderCost'] as const;
const DEFAULT_EXPANSION_DEPTH = 2;
const ROW_HEIGHT = 48;
const HEADER_HEIGHT = 56;

const createColumn = (key: string): GridColDef => ({
  ...costingGridColumnDefinition,
  field: key,
  headerName: key,
  type: 'number',
  flex: 0.15,
  headerAlign: 'left',
  align: 'left',
  renderCell: (params: GridRenderCellParams) => formatValueCell(params.value as number, { withCurrency: true }),
});

const groupingColDef: DataGridProProps['groupingColDef'] = {
  ...costingGridColumnDefinition,
  headerName: 'Type',
  flex: 0.4,
  hideDescendantCount: true,
};

export const getDepthClass = (depth: number): string => `hierarchyLevel${depth + 1}`;

const extractColumns = memoize((data: CostEntity[]): GridColDef[] => {
  const sample = data[0];
  if (!sample) return [];

  return Object.keys(omit(sample, EXCLUDED_KEYS)).map(createColumn);
});

function UniversitySummaryTable() {
  const { universitySummaryTable } = useBudgetStateContext();
  const isTestEnv = process.env.NODE_ENV === 'test';

  const columns = useMemo(() => extractColumns(universitySummaryTable), [universitySummaryTable]);
  const rows = useMemo(() => flattenRows(universitySummaryTable), [universitySummaryTable]);

  const getRowClassName = useCallback((params: { row: GridRowModel }) => {
    const { depth } = params.row as { depth: number };
    return getDepthClass(depth);
  }, []);

  const getTreeDataPath = useCallback((row: GridRowModel) => (row as { Hierarchy: string[] }).Hierarchy, []);

  return (
    <DataGridPro
      sx={(theme: Theme) => getSummaryStyles(theme)}
      getRowClassName={getRowClassName}
      treeData
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
      defaultGroupingExpansionDepth={DEFAULT_EXPANSION_DEPTH}
      autoHeight
      rowHeight={ROW_HEIGHT}
      columnHeaderHeight={HEADER_HEIGHT}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      hideFooter
      disableColumnReorder
      disableVirtualization={isTestEnv}
      density="compact"
      loading={universitySummaryTable.length === 0}
    />
  );
}

export default React.memo(UniversitySummaryTable);
