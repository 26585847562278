import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useEventListener } from 'usehooks-ts';

import BudgetTabs from './budget-tabs';

import { ErmCommand, ErmCommandNames } from '@/types';
import { ActionType, useBudgetDispatchContext, useBudgetStateContext } from '@forms/contexts/budget-context';
import SummaryTiles from '@forms/features/summary/summary-tiles';
import preawardServiceApi from '@forms/services/preaward-api';

function Budget() {
  const [searchParams] = useSearchParams();
  const dispatch = useBudgetDispatchContext();
  const { isDirty, sections, settings, budgetLastSyncedAt } = useBudgetStateContext();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const formId = searchParams.get('formId')!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const pageId = searchParams.get('pageId')!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const userProjectFormId = searchParams.get('userProjectFormId')!;
  const submissionId = searchParams.get('submissionId');

  const postMessageToParent = (command: string, value: boolean) => window.parent.postMessage({ command, value }, '*');

  const saveForm = async (updatedPageId: string) => {
    const success = await preawardServiceApi.saveCostingsInForm(
      formId,
      updatedPageId,
      userProjectFormId,
      sections,
      settings,
    );
    postMessageToParent(ErmCommandNames.formSaved, success);
  };

  useEffect(() => {
    if (isDirty) {
      postMessageToParent(ErmCommandNames.isDirty, true);
    }
  }, [isDirty]);

  useEventListener('message', (event: MessageEvent<ErmCommand>) => {
    const handleMessage = async () => {
      if (event.data.command === ErmCommandNames.saveForm && isDirty) {
        await saveForm(event.data.pageId);
        dispatch({
          type: ActionType.BUDGET_SAVED,
        });
      }
    };

    handleMessage().catch(() => {});
  });

  useEffect(() => {
    // Don't fetch summary data until we've got the budget data
    if (!budgetLastSyncedAt) {
      return;
    }

    const getUniversitySummaryTable = async () => {
      const summaryData = submissionId
        ? await preawardServiceApi.getUniversitySummarySubmission(formId, pageId, submissionId)
        : await preawardServiceApi.getUniversitySummary(formId, pageId, userProjectFormId, settings);
      dispatch({ type: ActionType.SET_UNIVERSITY_SUMMARY_TABLE, payload: summaryData.data });
    };

    getUniversitySummaryTable().catch(() => {});

    const getFunderSummaryTable = async () => {
      const summaryData = submissionId
        ? await preawardServiceApi.getFunderSummarySubmission(formId, pageId, submissionId)
        : await preawardServiceApi.getFunderSummary(formId, pageId, userProjectFormId, settings);
      dispatch({ type: ActionType.SET_FUNDER_SUMMARY_TABLE, payload: summaryData.data });
    };

    getFunderSummaryTable().catch(() => {});
  }, [formId, pageId, userProjectFormId, submissionId, settings, dispatch, budgetLastSyncedAt]);

  return (
    <Grid container sx={{ px: 2 }}>
      <Grid item xs={12}>
        <SummaryTiles />
      </Grid>
      <Grid item xs={12} id="tabs">
        <BudgetTabs
          formId={formId}
          pageId={pageId}
          userProjectFormId={userProjectFormId}
          submissionId={submissionId}
          readOnly={submissionId !== null}
        />
      </Grid>
    </Grid>
  );
}

export default Budget;
