/* eslint-disable max-classes-per-file */
import { GridRowModel } from '@mui/x-data-grid-pro';
import { ReactNode } from 'react';

import { Option } from './components/material-combobox';

/* eslint-disable max-classes-per-file */

export enum ColumnValueType {
  none = '',
  text = 'text',
  number = 'number',
  dropdown = 'dropdown',
  calculated = 'calculated',
  datetime = 'datetime',
  table = 'table',
  external = 'external',
}

export enum ColumnNames {
  Periods = 'Periods',
  From = 'From',
  To = 'To',
  EffortUnit = 'EffortUnit',
  Effort = 'Effort',
  Location = 'Location',
  Cost = 'Cost',
  ProjectCost = 'Project Cost',
  ConsumptionUnits = 'Consumption Units',
  PayScale = 'Pay Scale',
  PayBand = 'PayBand',
  StaffMember = 'StaffMember',
}

export enum Paths {
  OrganisationalUnit = 'OrganisationalUnit',
  SalaryBands = 'SalaryBands',
  Staff = 'Staff',
}

export enum GridViewFieldNames {
  ProjectDurationPeriod = 'ProjectDurationPeriod',
  CustomPeriods = 'CustomPeriods',
  Actions = 'Actions',
}

export enum Sections {
  StaffCost = 'Staff Cost',
  NonStaff = 'Non-Staff',
  FacilitiesAndServices = 'Facilities&Services',
  Partners = 'Partners',
  Students = 'Students',
}

export enum ErmCommandNames {
  isDirty = 'isDirty',
  formSaved = 'formSaved',
  saveForm = 'saveForm',
}

export enum Categories {
  Staff = 'Staff',
  NonHrStaff = 'Non-HR Staff',
  BandedStaff = 'Banded Staff',
  NonStaff = 'Non-Staff',
  MainCategory = 'Main Category',
  Studentship = 'Studentship',
  OtherStudentCosts = 'Other Student Costs',
}

export interface CostingsDto {
  formId: string;
  sections: CostingSection[];
  budgetSettings: BudgetSettings;
}

export interface CostingSection {
  id: string;
  name: string;
  displayName?: string;
  categories: CostingCategory[];
}

export interface CostingCategory {
  id: string;
  name: string;
  columnDefinitions: ColumnDefinition[];
  rows: GridRowModel[];
}

export interface ColumnDefinition {
  $type: ColumnValueType;
  name: string;
  displayName?: string | undefined;
}

export interface DropdownColumnOptions {
  options: DropdownColumnOption[];
}

export interface DropdownColumnOption {
  name: string;
  value: string;
}
export function isStaffModel(obj: unknown): obj is StaffModel {
  return (
    typeof obj === 'object' &&
    typeof (obj as StaffModel).id === 'string' &&
    typeof (obj as StaffModel).firstName === 'string' &&
    typeof (obj as StaffModel).lastName === 'string' &&
    typeof (obj as StaffModel).organisationalUnitId === 'string' &&
    typeof (obj as StaffModel).grade === 'string' &&
    typeof (obj as StaffModel).scalePoint === 'string' &&
    typeof (obj as StaffModel).payIncrease === 'string' &&
    typeof (obj as StaffModel).fullName === 'string'
  );
}

export interface StaffModel {
  id: string;
  firstName: string;
  lastName: string;
  organisationalUnitId: string;
  grade: string;
  scalePoint: string;
  payIncrease: string;
  fullName: string;
}

export interface StaffModelDto {
  data: StaffModel[];
}

export type ExternalDataItem = {
  id?: string; // TODO: Make this required once we've ensured all entities have ids
  name: string;
  unitCost?: number;
} & Record<string, unknown>;

export type SalaryBand = {
  id: string;
  name: string;
  grades: string[];
};

export interface CostEntity {
  Type: string;
  'Project Cost': string;
  'Funder Cost': string;
  Income: string;
  'Surplus/Deficit': string;
  FunderTag: string;
  UnOverriddenFunderCost: string;
  'FEC Recovery %': string;
  UnOverriddenFunderRecoveryPercentage: string;
  Children: CostEntity[] | null;
}

export interface CostEntityDto {
  data: CostEntity[];
}

export interface Period {
  From: Date;
  To: Date;
}

export interface PeriodWithEffort extends Period {
  EffortUnit: Option;
  Effort: number;
}

export interface StaffPeriod extends PeriodWithEffort {
  Location: Option;
}

export interface PeriodWithCost extends Period {
  Cost: number;
}

export interface FacilitiesAndServicesPeriod extends Period {
  'Consumption Units': number;
  'Project Cost': number;
}

export interface FacilitiesAndServicesData {
  id: string;
  name: string;
  unitCost: number;
}

export interface FacilitiesAndServicesDataDto {
  data: FacilitiesAndServicesData[];
}

export interface BudgetSettings {
  funderId?: string;
  pricingSchemeName?: string;
  projectStartDate?: string;
  projectEndDate?: string;
  funderCostOverrides: FunderCostOverride[];
  funderRecoveryOverrides: FunderRecoveryOverride[];
}

export interface FunderCostOverride {
  funderId: string;
  funderTag: string;
  funderCostMultiplier: number;
}

export interface FunderRecoveryOverride {
  funderId: string;
  funderTag: string;
  funderRecoveryPercentage: number;
}

export enum PeriodType {
  'ProjectDuration' = 'ProjectDuration',
  'Custom' = 'Custom',
}

export type GridRowModelWithPeriods = GridRowModel &
  (
    | {
        PeriodType: PeriodType.ProjectDuration;
        [GridViewFieldNames.ProjectDurationPeriod]: Record<string, unknown>[];
        [GridViewFieldNames.CustomPeriods]?: Record<string, unknown>[];
      }
    | {
        PeriodType: PeriodType.Custom;
        [GridViewFieldNames.ProjectDurationPeriod]?: Record<string, unknown>[];
        [GridViewFieldNames.CustomPeriods]: Record<string, unknown>[];
      }
  );

class TableColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  displayName?: string | undefined;

  columnDefinitions: ColumnDefinition[];

  constructor(name: string, columnDefinitions: ColumnDefinition[], displayName?: string | undefined) {
    this.$type = ColumnValueType.table;
    this.name = name;
    this.displayName = displayName;
    this.columnDefinitions = columnDefinitions;
  }
}

class DropdownColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  displayName?: string | undefined;

  options: DropdownColumnOption[];

  constructor(name: string, dropdown: DropdownColumnOptions, displayName?: string | undefined) {
    this.$type = ColumnValueType.dropdown;
    this.name = name;
    this.displayName = displayName;
    this.options = dropdown.options;
  }
}

class ExternalColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  displayName?: string | undefined;

  path: string;

  propertyName: string;

  filterable: boolean;

  secondaryPropertyName?: string;

  constructor(
    name: string,
    path: string,
    propertyName: string,
    filterable: boolean,
    displayName?: string | undefined,
    secondaryPropertyName?: string,
  ) {
    this.$type = ColumnValueType.external;
    this.name = name;
    this.path = path;
    this.propertyName = propertyName;
    this.filterable = filterable;
    this.displayName = displayName;
    this.secondaryPropertyName = secondaryPropertyName;
  }
}

export { DropdownColumnDefinition, TableColumnDefinition, ExternalColumnDefinition };

export interface ErmCommand {
  command: string;
  pageId: string;
}

export type ChildrenType = {
  children: ReactNode;
};

export interface SectionTotal {
  sectionId: string;
  sectionName: string;
  categoryId: string;
  total: number;
}

export interface ExternalDataType {
  name: string;
  path: string;
  propertyNames: string[];
  filterable: boolean;
}

export interface ExternalDataTypes {
  externalDataTypes: ExternalDataType[];
}

export enum Status {
  ADD,
  UPDATE,
}
class TextColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  displayName?: string | undefined;

  constructor(name: string, displayName?: string | undefined) {
    this.$type = ColumnValueType.text;
    this.name = name;
    this.displayName = displayName;
  }
}

class DateTimeColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  displayName?: string | undefined;

  constructor(name: string, displayName?: string | undefined) {
    this.$type = ColumnValueType.datetime;
    this.name = name;
    this.displayName = displayName;
  }
}

class NumberColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  displayName?: string | undefined;

  constructor(name: string, displayName?: string | undefined) {
    this.$type = ColumnValueType.number;
    this.name = name;
    this.displayName = displayName;
  }
}

class CalculatedColumnDefinition implements ColumnDefinition {
  $type: ColumnValueType;

  name: string;

  displayName?: string | undefined;

  expression: string;

  constructor(name: string, expression: string, displayName?: string | undefined) {
    this.$type = ColumnValueType.calculated;
    this.name = name;
    this.displayName = displayName;
    this.expression = expression;
  }
}

export { TextColumnDefinition, DateTimeColumnDefinition, NumberColumnDefinition, CalculatedColumnDefinition };

declare module '@mui/x-data-grid-pro' {
  interface FooterPropsOverrides {
    total: number;
  }

  interface ColumnMenuPropsOverrides {
    handleEditColumn: (headerName: string) => void;
    handleDeleteColumn: (headerName: string) => void;
  }
}
