import { blueGrey, red } from '@mui/material/colors';
import {
  PaletteOptions,
  SimplePaletteColorOptions,
  ThemeOptions,
  alpha,
  createTheme,
  responsiveFontSizes,
} from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    neutral?: PaletteOptions['primary'];
  }
}

interface ExtendedThemeOptions extends ThemeOptions {
  palette: ThemeOptions['palette'] & {
    neutral?: PaletteOptions['primary'];
  };
}

const themePalette: PaletteOptions = {
  primary: {
    main: '#2468C4',
    light: '#2B74DAC5',
    dark: '#1462CF',
  },
  secondary: {
    main: blueGrey[600],
    light: blueGrey[400],
    dark: blueGrey[800],
  },
  error: {
    main: red[600],
  },
  background: {
    default: '#FFFFFF',
    paper: '#DDE2E7',
  },
  neutral: {
    main: blueGrey[200],
    light: blueGrey[50],
    dark: blueGrey[400],
  },
  text: {
    primary: '#333333',
    secondary: '#8B929C',
  },
};

const themeOptions: ExtendedThemeOptions = {
  palette: themePalette,
  typography: {
    fontFamily: ['DM Sans', 'sans-serif'].join(','),
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.75rem',
      fontWeight: 500,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    h5: {
      fontSize: '1.25rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875rem',
      lineHeight: 1.5,
    },
    button: {
      textTransform: 'none',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '0.875rem',
      fontWeight: 500,
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: themePalette.background?.default,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // Disable text uppercasing globally
          borderRadius: 8,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: 4,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingTop: '12px',
          paddingBottom: '12px',
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
        icon: {
          color: themePalette.text?.primary,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: alpha((themePalette.primary as SimplePaletteColorOptions)?.main, 0.7),
          },
          '&.Mui-selected': {
            backgroundColor: alpha((themePalette.primary as SimplePaletteColorOptions)?.main, 0.7),
            '&:hover': {
              backgroundColor: alpha((themePalette.primary as SimplePaletteColorOptions)?.main, 0.7),
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '.MuiDataGrid-root &:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
          '.MuiDataGrid-root &.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiDialog-paper': {
            maxWidth: 'lg',
            width: '100%',
          },
        },
        paper: {
          margin: 0,
        },
      },
      defaultProps: {
        fullWidth: true,
        maxWidth: 'lg',
      },
    },
  },
};

let theme = createTheme(themeOptions);

theme = responsiveFontSizes(theme);

theme = createTheme(theme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarWidth: 'thin',
          '&::-webkit-scrollbar': {
            width: '6px',
            height: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: theme.palette.background.default,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.light,
            borderRadius: '3px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: theme.palette.primary.main,
          },
        },
      },
    },
  },
});

const appTheme = theme;

export default appTheme;
