import { Box } from '@mui/material';
import {
  GRID_DATE_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridRowId,
  GridValueFormatterParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';

import { dateFormat } from '@/utils';

type GridValidRowModel = {
  [key: string | symbol]: string;
};

interface GridEditDateCellProps extends Readonly<GridRenderEditCellParams<GridValidRowModel, Dayjs | null, string>> {
  id: GridRowId;
  field: string;
  value: Dayjs | null;
}

function GridEditDateCell(props: Readonly<GridEditDateCellProps>) {
  const { id, field, value } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (newValue: unknown) => {
    await apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  return <DatePicker value={value} format={dateFormat} autoFocus onChange={handleChange} />;
}

const addDateTimeColumnDefinition = ({
  baseColDef,
  readOnly,
}: {
  baseColDef: GridColDef;
  readOnly: boolean;
}): GridColDef => ({
  ...GRID_DATE_COL_DEF,
  ...baseColDef,
  resizable: false,
  editable: !readOnly,
  renderCell: (params: GridRenderCellParams) => <Box>{dayjs(params.value as string).format(dateFormat)}</Box>,
  renderEditCell: (params: GridRenderCellParams) => (
    <GridEditDateCell {...params} value={dayjs(params.value as dayjs.ConfigType)} />
  ),
  valueFormatter: (params: GridValueFormatterParams<dayjs.ConfigType>) => dayjs(params.value).format(dateFormat),
});

export default addDateTimeColumnDefinition;
