import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Box, Button, Grid, Typography } from '@mui/material';
import { ConfirmOptions, useConfirm } from 'material-ui-confirm';

import { Option, isComboboxOption } from '@/components/material-combobox';

function CreateConfirmOptions(title: string, name: string | Option | undefined): ConfirmOptions {
  return {
    title: '',
    dialogProps: {
      maxWidth: 'sm',
    },
    content: (
      <Grid container paddingRight={4} paddingBottom={2}>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="center" paddingTop={2}>
            <WarningAmberIcon color="error" fontSize="large" />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box>
            <Typography variant="h6">{title}</Typography>
            <Grid sx={{ mt: 1 }}>
              <Grid id="delete-column-dialog-desription">
                <Typography variant="body1">
                  Are you sure you want to delete{' '}
                  <strong>{(isComboboxOption(name) ? name.label : name) || 'this row'}</strong>?
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    ),
    cancellationText: 'Cancel',
    cancellationButtonProps: {
      variant: 'text',
      color: 'primary',
      id: 'delete-column-dialog-cancel',
    },
    confirmationText: 'Delete',
    confirmationButtonProps: {
      variant: 'contained',
      color: 'error',
      id: 'delete-column-dialog-confirm',
    },
  };
}

export default function DeleteRowIcon(
  props: Readonly<{
    name: string;
    callback: () => void;
  }>,
) {
  const confirm = useConfirm();
  const options = CreateConfirmOptions('Confirm Delete', props.name);
  return (
    <Button
      size="small"
      variant="text"
      sx={(theme) => ({
        color: theme.palette.error.main,
        padding: '0px',
        minWidth: '30px',
        width: '30px',
        height: '30px',
        '&:hover': {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.common.white,
        },
      })}
      onClick={() => {
        confirm(options)
          .then(() => props.callback())
          .catch(() => {});
      }}
    >
      <DeleteOutlineIcon fontSize="small" />
    </Button>
  );
}
