import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Skeleton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';

import CostingsTable from './table';

import { trackException } from '@/application-insights';
import { Option } from '@/components/material-combobox';
import { useBudgetStateContext } from '@/forms/contexts/budget-context';
import { CostEntity } from '@/types';
import preawardServiceApi from '@forms/services/preaward-api';
import { costingGridColumnDefinition, flattenRows, formatValueCell } from '@forms/utils';

export interface CostingTableDialogProps {
  sectionName: string;
  categoryName: string;
  parentRow: GridRowModel;
  open: boolean;
  handleClose: () => void;
}

function CostingTableDialog(props: Readonly<CostingTableDialogProps>) {
  const { sectionName, categoryName, parentRow, open, handleClose } = props;
  const [rows, setRows] = useState<GridRowModel[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);
  const [loading, setLoading] = useState(true);
  const { settings } = useBudgetStateContext();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  function setCostingColumns(data: CostEntity[]) {
    const keys = Object.keys(data[0]).filter(
      (key) =>
        key !== 'Children' && key !== 'FunderTag' && key !== 'FEC Recovery %' && key !== 'UnOverriddenFunderCost',
    );
    const cols: GridColDef[] = keys.map((key) => ({
      ...costingGridColumnDefinition,
      field: key,
      headerName: key,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => formatValueCell(params.value as number, { withCurrency: true }),
    }));

    setColumns(cols);
  }

  useEffect(() => {
    const getCostingsTable = async () => {
      const data = await preawardServiceApi.getCostingsTable(sectionName, categoryName, parentRow, settings);
      setCostingColumns(data.data);
      setRows(flattenRows(data.data));
      setLoading(false);
    };

    if (open && parentRow) {
      setRows([]);
      setLoading(true);
      getCostingsTable().catch((err: Error) => {
        trackException(err);
        setLoading(false);
      });
    }
  }, [open, sectionName, categoryName, parentRow, settings]);

  return (
    <Dialog
      id={`${sectionName}-${categoryName}-costing-table-dialog`}
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="costing-table-dialog-title"
      aria-describedby="costing-table-dialog-description"
      data-testid={`${sectionName}-${categoryName}-costing-table-dialog`}
    >
      <DialogTitle>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h5" component="span">
              {parentRow.DisplayName ??
                (parentRow.Name as Option)?.label ??
                parentRow.Name ??
                (parentRow.Item as Option)?.label ??
                (parentRow.University as Option)?.label}{' '}
              Costing Table
            </Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <IconButton onClick={handleClose} sx={{ marginInlineEnd: '-1rem' }}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        {loading ? (
          <Skeleton data-testid="loadingSkeleton" variant="rectangular" />
        ) : (
          <CostingsTable columns={columns} rows={rows} uniqueKey="id" />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CostingTableDialog;
