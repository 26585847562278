import { Theme, alpha } from '@mui/material';

const getSummaryStyles = (theme: Theme) => ({
  '& .MuiDataGrid-root': {
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden',
  },
  '.hierarchyLevel1': {
    bgcolor: alpha(theme.palette.primary.main, 0.08),
    fontWeight: 'bold',
  },
  '.hierarchyLevel2': {
    bgcolor: alpha(theme.palette.primary.main, 0.06),
    fontWeight: 600,
  },
  '.hierarchyLevel3': {
    bgcolor: alpha(theme.palette.primary.main, 0.04),
  },
  '.hierarchyLevel4': {
    bgcolor: alpha(theme.palette.primary.main, 0.02),
  },
  '& .MuiDataGrid-cell': {
    borderColor: alpha(theme.palette.primary.main, 0.1),
    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-columnHeader': {
    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-cell--editable': {
    border: '1px solid',
    borderColor: 'grey.500',
    borderRadius: 2,
    '&.value-overridden': {
      backgroundColor: alpha(theme.palette.warning.light, 0.2),
      borderColor: theme.palette.warning.light,
    },
    '& input': {
      padding: '0 16px',
      height: '100%',
      border: 'none',
      outline: 'none',
    },
    '& fieldset': {
      display: 'none',
    },
  },
  '& .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within': {
    backgroundColor: alpha(theme.palette.info.light, 0.2),
    borderColor: theme.palette.info.light,
    outline: theme.palette.info.light,
  },
});

export default getSummaryStyles;
