import { ReactElement, ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';
import { Guid } from 'typescript-guid';

import { ColumnDefinition, ColumnValueType, CostingCategory, CostingSection, TableColumnDefinition } from '@/types';

const defaultColumn: ColumnDefinition = {
  $type: ColumnValueType.calculated,
  name: 'Funds Requested',
};

const defaultCategory = () => ({
  id: Guid.create().toString(),
  name: '',
  columnDefinitions: [defaultColumn],
  rows: [],
});

const defaultSection = () => ({
  id: Guid.create().toString(),
  name: '',
  categories: [defaultCategory()],
});

type StateType = {
  costingSections: CostingSection[];
  addSection: (newSection?: CostingSection) => void;
  deleteSection: (sectionId: string) => void;
  updateSection: (section: CostingSection) => void;
  setSections: (costingSections?: CostingSection[]) => void;
  addCategory: (sectionId: string, newCategory?: CostingCategory) => void;
  deleteCategory: (sectionId: string, categoryId: string) => void;
  updateCategory: (sectionId: string, category: CostingCategory) => void;
  addColumn: (sectionId: string, categoryId: string, column: ColumnDefinition) => void;
  updateColumn: (sectionId: string, categoryId: string, existingColumnName: string, column: ColumnDefinition) => void;
  deleteColumn: (sectionId: string, categoryId: string, columnName: string) => void;
};

const initialState: StateType = {
  costingSections: [],
  addSection: () => {},
  deleteSection: () => {},
  updateSection: () => {},
  setSections: () => {},
  addCategory: () => {},
  deleteCategory: () => {},
  updateCategory: () => {},
  addColumn: () => {},
  updateColumn: () => {},
  deleteColumn: () => {},
};

export const SectionContext = createContext<StateType>(initialState);

export function CostingReviewContextProvider({ children }: Readonly<{ children: ReactNode }>): ReactElement {
  const [costingSections, setCostingSections] = useState<CostingSection[]>(initialState.costingSections);

  const addSection = useCallback(
    (newSection?: CostingSection) => {
      const updatedSections = costingSections.concat([newSection ?? defaultSection()]);
      setCostingSections(updatedSections);
    },
    [costingSections],
  );

  const deleteSection = useCallback(
    (sectionId: string) => {
      const updatedSections = costingSections.filter((costing) => costing.id !== sectionId);
      setCostingSections(updatedSections);
    },
    [costingSections],
  );

  const updateSection = useCallback(
    (section: CostingSection) => {
      const updatedSections = costingSections.map((obj) => {
        if (obj.id === section.id) {
          return section;
        }
        return obj;
      });
      setCostingSections(updatedSections);
    },
    [costingSections],
  );

  const setSections = (sections?: CostingSection[]) => {
    setCostingSections(sections ?? [defaultSection()]);
  };

  const addCategory = useCallback(
    (sectionId: string, newCategory?: CostingCategory) => {
      const currentSection = costingSections.find((sec) => sec.id === sectionId);
      if (currentSection) {
        currentSection.categories = (currentSection?.categories || []).concat([newCategory ?? defaultCategory()]);
        updateSection(currentSection);
      }
    },
    [costingSections, updateSection],
  );

  const deleteCategory = useCallback(
    (sectionId: string, categoryId: string) => {
      const currentSection = costingSections.find((sec) => sec.id === sectionId);

      if (currentSection) {
        currentSection.categories = currentSection?.categories.filter((category) => category.id !== categoryId);
        updateSection(currentSection);
      }
    },
    [costingSections, updateSection],
  );

  const updateCategory = useCallback(
    (sectionId: string, category: CostingCategory) => {
      const currentSection = costingSections.find((sec) => sec.id === sectionId);
      const updatedCategories = currentSection?.categories.map((obj) => {
        if (obj.id === category.id) {
          return category;
        }
        return obj;
      });

      if (currentSection) {
        currentSection.categories = updatedCategories || [];

        updateSection(currentSection);
      }
    },
    [costingSections, updateSection],
  );

  const addColumn = useCallback(
    (sectionId: string, categoryId: string, column: ColumnDefinition) => {
      const currentSection = costingSections.find((sec) => sec.id === sectionId);

      const currentCategory = currentSection?.categories.find((cat) => cat.id === categoryId);

      if (currentCategory) {
        currentCategory.columnDefinitions = [column].concat(currentCategory?.columnDefinitions || []);

        updateCategory(sectionId, currentCategory);
      }
    },
    [costingSections, updateCategory],
  );

  const updateColumn = useCallback(
    (sectionId: string, categoryId: string, existingColumnName: string, column: ColumnDefinition) => {
      const currentSection = costingSections.find((sec) => sec.id === sectionId);

      const currentCategory = currentSection?.categories.find((cat) => cat.id === categoryId);

      if (currentCategory) {
        const updatedColumns = currentCategory?.columnDefinitions.map((col) => {
          if (col.name === existingColumnName) {
            return column;
          }
          return col;
        });

        currentCategory.columnDefinitions = updatedColumns || [];
        updateCategory(sectionId, currentCategory);
      }
    },
    [costingSections, updateCategory],
  );

  const deleteNestedColumn = useCallback((columns: ColumnDefinition[], columnName: string): ColumnDefinition[] => {
    const updatedCols: ColumnDefinition[] = [];
    columns?.forEach((col) => {
      if (col.$type === ColumnValueType.table && col.name !== columnName) {
        const updatedCol = { ...col } as TableColumnDefinition;
        updatedCol.columnDefinitions = deleteNestedColumn((col as TableColumnDefinition).columnDefinitions, columnName);
        updatedCols.push(updatedCol);
      } else if (col.name !== columnName) {
        updatedCols.push(col);
      }
    });
    return updatedCols;
  }, []);

  const deleteColumn = useCallback(
    (sectionId: string, categoryId: string, columnName: string) => {
      const currentSection = costingSections.find((sec) => sec.id === sectionId);

      const currentCategory = currentSection?.categories.find((cat) => cat.id === categoryId);

      if (currentCategory) {
        const updatedColumns = deleteNestedColumn(currentCategory?.columnDefinitions, columnName);

        currentCategory.columnDefinitions = updatedColumns || [];
        updateCategory(sectionId, currentCategory);
      }
    },
    [costingSections, deleteNestedColumn, updateCategory],
  );

  const value = useMemo(
    () => ({
      costingSections,
      addSection,
      deleteSection,
      updateSection,
      setSections,
      addCategory,
      deleteCategory,
      updateCategory,
      addColumn,
      updateColumn,
      deleteColumn,
    }),
    [
      costingSections,
      addSection,
      deleteSection,
      updateSection,
      addCategory,
      deleteCategory,
      updateCategory,
      addColumn,
      updateColumn,
      deleteColumn,
    ],
  );
  return <SectionContext.Provider value={value}>{children}</SectionContext.Provider>;
}

export const useCostingReviewContext = () => useContext(SectionContext);
