import { Theme, alpha } from '@mui/material';
import {
  DataGridPro,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRowId,
  GridRowModel,
  GridValidRowModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { isEqual } from 'lodash';
import React, { useCallback } from 'react';

import { getDepthClass } from '@/forms/features/summary/university-summary-table';

interface CostingsTableProps {
  columns: GridColDef[];
  rows: GridRowModel[];
  uniqueKey: string;
  onProcessRowUpdate?: (newRow: GridValidRowModel) => void;
  onRowClick?: (row: GridRowModel) => void;
}

const ROW_HEIGHT = 44;
const HEADER_HEIGHT = 40;

const getDataGridStyles = (theme: Theme) => ({
  '.hierarchyLevel1': {
    bgcolor: alpha(theme.palette.primary.main, 0.08),
    fontWeight: 'bold',
  },
  '.hierarchyLevel2': {
    bgcolor: alpha(theme.palette.primary.main, 0.06),
    fontWeight: 600,
  },
  '& .MuiDataGrid-root': {
    border: '1px solid rgba(224, 224, 224, 1)',
    borderRadius: 2,
    overflow: 'hidden',
    width: '100%',
  },
  '& .MuiDataGrid-cell': {
    borderColor: alpha(theme.palette.primary.main, 0.1),
    borderRight: '1px solid rgba(224, 224, 224, 1)',
    fontWeight: 500,
    fontSize: '0.875rem',
    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },
  '& .MuiDataGrid-columnHeaders': {
    color: theme.palette.secondary.light,
    fontWeight: 'bold',
    fontSize: '0.775rem',
  },
  '& .MuiDataGrid-columnHeader': {
    '&:focus, &:focus-within': {
      outline: 'none',
    },
  },
});

function CostingsTable({ columns, rows, uniqueKey, onProcessRowUpdate, onRowClick }: Readonly<CostingsTableProps>) {
  const isTestEnv = process.env.NODE_ENV === 'test';
  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});
  const apiRef = useGridApiRef();

  const handleCellClick = useCallback((params: GridCellParams) => {
    if (!params.isEditable) return;

    setCellModesModel((prevModel) => {
      const updatedModel = { ...prevModel };
      const rowModes = { ...(updatedModel[params.id] || {}) };

      Object.keys(rowModes).forEach((field) => {
        rowModes[field] = { mode: GridCellModes.View };
      });

      rowModes[params.field] = { mode: GridCellModes.Edit };

      updatedModel[params.id] = rowModes;
      return updatedModel;
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const getRowId = useCallback((row: GridRowModel) => row[uniqueKey] as GridRowId, [uniqueKey]);

  const processRowUpdate = useCallback(
    (newRow: GridValidRowModel) => {
      if (onProcessRowUpdate) {
        onProcessRowUpdate(newRow);
      }
      return newRow;
    },
    [onProcessRowUpdate],
  );

  const handleRowClick = useCallback(
    (params: { row: GridRowModel }) => {
      if (onRowClick) {
        onRowClick(params.row);
      }
    },
    [onRowClick],
  );

  const getRowClassName = useCallback((params: { row: GridRowModel }) => {
    const { depth } = params.row as { depth: number };
    return getDepthClass(depth);
  }, []);

  return (
    <DataGridPro
      sx={getDataGridStyles}
      getRowId={getRowId}
      getRowClassName={getRowClassName}
      apiRef={apiRef}
      autoHeight
      rowHeight={ROW_HEIGHT}
      columnHeaderHeight={HEADER_HEIGHT}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      hideFooter
      disableColumnReorder
      disableColumnResize
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      processRowUpdate={processRowUpdate}
      onRowClick={handleRowClick}
      disableVirtualization={isTestEnv}
    />
  );
}

export default React.memo(
  CostingsTable,
  (prevProps, nextProps) =>
    isEqual(prevProps.columns, nextProps.columns) &&
    isEqual(prevProps.rows, nextProps.rows) &&
    prevProps.uniqueKey === nextProps.uniqueKey,
);
