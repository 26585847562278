import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';

import StaffAutocomplete from './staff-autocomplete';

import type { Option } from '@/components/material-combobox';
import NameAvatarCell from '@/components/name-avatar-cell';
import { ColumnDefinition, ExternalColumnDefinition, StaffModel } from '@/types';

function addExternalFilterableColumnDefinition(colDefs: GridColDef, column: ColumnDefinition, readonly: boolean) {
  const externalColumnDefinition = column as ExternalColumnDefinition;
  return {
    ...colDefs,
    type: 'singleSelect',
    editable: !readonly,
    renderCell: (params: GridRenderCellParams) => NameAvatarCell({ text: (params.value as Option).label as string}),
    renderEditCell: (params: GridRenderCellParams) => (
      <StaffAutocomplete
        {...params}
        path={externalColumnDefinition.path}
        propertyName={externalColumnDefinition.propertyName}
      />
    ),
    valueParser: (staff: StaffModel) => ({ label: staff.fullName, value: staff.id } as Option),
  };
}

export default addExternalFilterableColumnDefinition;
