import { CloseRounded } from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { alpha, useTheme } from '@mui/material/styles';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import _ from 'lodash';
import React, { useRef, useState } from 'react';

export interface Option {
  value: string | number;
  label: string | number;
}

export const isComboboxOption = (value: unknown): value is Option => !!(value as Option | undefined)?.label;

export interface MaterialComboboxProps {
  options?: Option[];
  placeholder?: string;
  onChange?: (option: Option | null) => void;
  initialValue?: Option | null;
  autoOpen?: boolean;
  id?: string;
}

function MaterialCombobox({
  options = [],
  placeholder = 'Select',
  onChange,
  initialValue,
  autoOpen,
  id,
}: Readonly<MaterialComboboxProps>) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(initialValue || null);
  const anchorRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const uniqueOptions = _.uniqBy(options, 'value');

  const handleSelect = (option: Option) => {
    onChange?.(option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  const handleClear = (e: React.MouseEvent) => {
    onChange?.(null);
    e.stopPropagation();
    setSelectedOption(null);
  };

  const handleClose = () => {
    onChange?.(selectedOption);
    setIsOpen(false);
  };

  React.useEffect(() => {
    if (autoOpen) {
      setIsOpen(true);
    }
  }, [autoOpen]);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box ref={anchorRef} sx={{ position: 'relative', width: '100%' }}>
        <Box
          sx={{
            position: 'relative',
            py: 1.5,
            px: 1,
            pr: 2,
            cursor: 'pointer',
            backgroundColor: 'transparent',
          }}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
          tabIndex={0}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {selectedOption?.label ? selectedOption.label : placeholder}
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {selectedOption?.value ? (
                <Button
                  onClick={handleClear}
                  sx={{
                    minWidth: 'auto',
                    p: 0.2,
                    '&:hover': { bgcolor: 'grey.200' },
                  }}
                >
                  <CloseRounded sx={{ fontSize: 18 }} />
                </Button>
              ) : (
                <Box
                  component="svg"
                  sx={{
                    width: 20,
                    height: 20,
                    color: 'grey.500',
                    transition: 'transform 200ms',
                    transform: isOpen ? 'rotate(180deg)' : 'none',
                  }}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                >
                  <ArrowDropDownIcon color="primary" />
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Popper
          open={isOpen}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          style={{ width: anchorRef.current?.clientWidth, zIndex: 9999 }}
        >
          <Paper
            id={`popper-${id ?? 'combobox'}`}
            sx={{
              maxHeight: 240,
              overflow: 'auto',
              borderRadius: '0 0 3px 3px',
              boxShadow:
                '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)',
            }}
          >
            {uniqueOptions.map((option) => (
              <MenuItem
                key={option.value}
                sx={{
                  px: 2,
                  py: 1.5,
                  cursor: 'pointer',
                  fontSize: '0.86rem',
                  fontWeight: selectedOption?.value === option.value ? '500' : 'normal',
                  backgroundColor:
                    selectedOption?.value === option.value ? alpha(theme.palette.primary.light, 0.6) : 'transparent',
                  '&:hover': {
                    bgcolor: selectedOption?.value === option.value ? theme.palette.primary.light : 'grey.100',
                  },
                }}
                onClick={() => handleSelect(option)}
                aria-selected={selectedOption?.value === option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}

export default MaterialCombobox;
