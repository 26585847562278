import { Clear as ClearIcon } from '@mui/icons-material';
import { IconButton, TextField, Theme, Tooltip } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
} from '@mui/x-data-grid-pro';
import { omit } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import getSummaryStyles from './summary-table-styles';

import { ActionType, useBudgetDispatchContext, useBudgetStateContext } from '@/forms/contexts/budget-context';
import { CostEntity, FunderCostOverride, FunderRecoveryOverride } from '@/types';
import { costingGridColumnDefinition, flattenRows, formatValueCell } from '@forms/utils';

const EXCLUDED_KEYS = ['Children', 'Type', 'FunderTag', 'Surplus/Deficit'] as const;

const UNOVERRIDDEN_FUNDER_COST_COLUMN = 'UnOverriddenFunderCost';
const FUNDER_COST_COLUMN = 'Funder Cost';
const UNOVERRIDDEN_FUNDER_RECOVERY_PERCENTAGE_COLUMN = 'UnOverriddenFunderRecoveryPercentage';
const FUNDER_RECOVERY_PERCENTAGE_COLUMN = 'FEC Recovery %';
const EDITABLE_COLUMNS = [FUNDER_COST_COLUMN, FUNDER_RECOVERY_PERCENTAGE_COLUMN];
const DEFAULT_EXPANSION_DEPTH = 2;
const ROW_HEIGHT = 48;
const HEADER_HEIGHT = 56;

const isColumnEditable = (key: string, formSubmitted: boolean): boolean =>
  !formSubmitted && EDITABLE_COLUMNS.includes(key);

const isCellEditable = (row: GridRowModel): boolean => {
  const hierarchy = (row as { Hierarchy: string[] }).Hierarchy;
  if (hierarchy.length === 2) return true;
  return false;
};

function EditableTextField({ cellParams }: Readonly<{ cellParams: GridRenderCellParams }>) {
  const commit = async (inputValue: string) => {
    // TODO: Fix '1.20' becoming '1.2'
    // TODO: Only show clear button when overridden
    const value = parseFloat(inputValue ?? '');

    const originalValue =
      cellParams.field === FUNDER_RECOVERY_PERCENTAGE_COLUMN
        ? (cellParams.row as { [UNOVERRIDDEN_FUNDER_RECOVERY_PERCENTAGE_COLUMN]: number })[
            UNOVERRIDDEN_FUNDER_RECOVERY_PERCENTAGE_COLUMN
          ]
        : (cellParams.row as { [UNOVERRIDDEN_FUNDER_COST_COLUMN]: number })[UNOVERRIDDEN_FUNDER_COST_COLUMN];

    await cellParams.api.setEditCellValue({
      id: cellParams.id,
      field: cellParams.field,
      value: Number.isNaN(value) ? originalValue : value,
    });
  };
  return (
    <TextField
      key={cellParams.field}
      value={cellParams.value as number}
      onChange={async (e) => {
        // Allow only digits and decimal points
        const rawValue = e.target.value;
        const numericValue = rawValue.replace(/[^0-9.]/g, '');

        await cellParams.api.setEditCellValue({
          id: cellParams.id,
          field: cellParams.field,
          value: numericValue ?? '',
        });
      }}
      onBlur={(e) => commit(e.target.value)}
      onKeyDown={async (e) => {
        if (e.key === 'Enter' && e.target instanceof HTMLTextAreaElement) await commit(e.target.value);
      }}
      InputProps={{
        role: 'cell-edit-input',
        endAdornment: (
          <Tooltip
            title={
              cellParams.field === FUNDER_COST_COLUMN
                ? 'Clear overriden funder cost'
                : 'Clear overriden FEC recovery percentage'
            }
          >
            <IconButton
              id={cellParams.field}
              size="small"
              aria-label="clear"
              onClick={async () => {
                await commit('');
                cellParams.api.stopCellEditMode({ id: cellParams.id, field: cellParams.field });
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        ),
      }}
      sx={{ width: '100%' }}
    />
  );
}

const createColumn = (key: string, formSubmitted: boolean): GridColDef => ({
  ...costingGridColumnDefinition,
  field: key,
  headerName: key,
  editable: isColumnEditable(key, formSubmitted),
  type: 'number',
  flex: 0.15,
  headerAlign: 'left',
  align: 'left',
  renderCell: (params: GridRenderCellParams) =>
    formatValueCell(params.value as number, { withCurrency: params.field !== FUNDER_RECOVERY_PERCENTAGE_COLUMN }),
  renderEditCell: (params: GridRenderCellParams<GridRowModel>) => <EditableTextField cellParams={params} />,
});

const groupingColDef: DataGridProProps['groupingColDef'] = {
  ...costingGridColumnDefinition,
  headerName: 'Type',
  flex: 0.4,
  hideDescendantCount: true,
};

export const getDepthClass = (depth: number): string => `hierarchyLevel${depth + 1}`;

const createColumns = (sample: CostEntity, formSubmitted: boolean): GridColDef[] => {
  if (!sample) return [];

  return Object.keys(omit(sample, EXCLUDED_KEYS)).map((key) => createColumn(key, formSubmitted));
};

function FunderSummaryTable() {
  const { settings, funderSummaryTable } = useBudgetStateContext();
  const dispatch = useBudgetDispatchContext();

  const [funderCostOverrides, setFunderCostOverrides] = useState<FunderCostOverride[]>(settings.funderCostOverrides);
  const [funderRecoveryOverrides, setFunderRecoveryOverrides] = useState<FunderRecoveryOverride[]>(
    settings.funderRecoveryOverrides,
  );

  const isTestEnv = process.env.NODE_ENV === 'test';

  const [cellModesModel, setCellModesModel] = React.useState<GridCellModesModel>({});

  const rows = useMemo(() => flattenRows(funderSummaryTable), [funderSummaryTable]);

  const [searchParams] = useSearchParams();
  const submissionId = searchParams.get('submissionId');
  const formSubmitted = !!submissionId;

  const columns = useMemo(
    () => createColumns(funderSummaryTable[0], formSubmitted),
    [funderSummaryTable, formSubmitted],
  );

  const getRowClassName = useCallback((params: { row: GridRowModel }) => {
    const { depth } = params.row as { depth: number };
    return getDepthClass(depth);
  }, []);

  const getCellClassName = useCallback(
    ({ row, field }: GridCellParams<GridRowModel>) => {
      if (!isCellEditable(row)) return '';

      if (
        field === FUNDER_COST_COLUMN &&
        settings.funderCostOverrides.some(
          (override) => override.funderId === settings.funderId && override.funderTag === row.FunderTag,
        )
      )
        return 'value-overridden';

      if (
        field === FUNDER_RECOVERY_PERCENTAGE_COLUMN &&
        settings.funderRecoveryOverrides.some(
          (override) => override.funderId === settings.funderId && override.funderTag === row.FunderTag,
        )
      )
        return 'value-overridden';

      return '';
    },
    [settings],
  );

  const getTreeDataPath = useCallback((row: GridRowModel) => (row as { Hierarchy: string[] }).Hierarchy, []);

  const calculateFunderCostMultiplier = (unOverriddenFunderCost: number, updatedFunderCost: number): number =>
    updatedFunderCost / unOverriddenFunderCost;

  const setFunderCostOverride = (funderTag: string, unOverriddenFunderCost: number, updatedFunderCost: number) => {
    const funderId = settings.funderId ?? '';

    const existingOverride = funderCostOverrides.find((o) => o.funderId === funderId && o.funderTag === funderTag);
    const updatedOverride = existingOverride
      ? {
          ...existingOverride,
          funderCostMultiplier: calculateFunderCostMultiplier(unOverriddenFunderCost, updatedFunderCost),
        }
      : {
          funderId,
          funderTag,
          funderCostMultiplier: calculateFunderCostMultiplier(unOverriddenFunderCost, updatedFunderCost),
        };

    const updatedOverrides = [
      ...funderCostOverrides.filter((o) => o.funderId !== funderId || o.funderTag !== funderTag),
      updatedOverride,
    ];
    setFunderCostOverrides(updatedOverrides);
    return updatedOverrides;
  };

  const clearFunderCostOverride = (funderTag: string) => {
    const funderId = settings.funderId ?? '';

    const updatedOverrides = funderCostOverrides.filter((o) => o.funderId !== funderId || o.funderTag !== funderTag);
    setFunderCostOverrides(updatedOverrides);
    return updatedOverrides;
  };

  const setFunderRecoveryPercentageOverride = (funderTag: string, updatedFunderRecoveryPercentage: number) => {
    const funderId = settings.funderId ?? '';

    const existingOverride = funderRecoveryOverrides.find((o) => o.funderId === funderId && o.funderTag === funderTag);
    const updatedOverride = existingOverride
      ? {
          ...existingOverride,
          funderRecoveryPercentage: updatedFunderRecoveryPercentage,
        }
      : {
          funderId,
          funderTag,
          funderRecoveryPercentage: updatedFunderRecoveryPercentage,
        };

    const updatedOverrides = [
      ...funderRecoveryOverrides.filter((o) => o.funderId !== funderId || o.funderTag !== funderTag),
      updatedOverride,
    ];
    setFunderRecoveryOverrides(updatedOverrides);
    return updatedOverrides;
  };

  const clearFunderRecoveryPercentageOverride = (funderTag: string) => {
    const funderId = settings.funderId ?? '';

    const updatedOverrides = funderRecoveryOverrides.filter(
      (o) => o.funderId !== funderId || o.funderTag !== funderTag,
    );
    setFunderRecoveryOverrides(updatedOverrides);
    return updatedOverrides;
  };

  const handleCellClick = useCallback((params: GridCellParams) => {
    if (!params.isEditable) return;

    setCellModesModel((prevModel) => {
      const updatedModel = { ...prevModel };
      const rowModes = { ...(updatedModel[params.id] || {}) };

      Object.keys(rowModes).forEach((field) => {
        rowModes[field] = { mode: GridCellModes.View };
      });

      rowModes[params.field] = { mode: GridCellModes.Edit };

      updatedModel[params.id] = rowModes;
      return updatedModel;
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const processRowUpdate = (newRow: GridRowModel, oldRow: GridRowModel) => {
    const editingFunderCost = newRow[FUNDER_COST_COLUMN] !== oldRow[FUNDER_COST_COLUMN];
    const editingFunderRecoveryPercentage =
      newRow[FUNDER_RECOVERY_PERCENTAGE_COLUMN] !== oldRow[FUNDER_RECOVERY_PERCENTAGE_COLUMN];

    if (!editingFunderCost && !editingFunderRecoveryPercentage) return newRow;

    const funderTag = newRow.FunderTag as string;

    if (editingFunderCost) {
      const newFunderCost = parseFloat(newRow[FUNDER_COST_COLUMN] as string);
      const unOverriddenFunderCost = parseFloat(newRow[UNOVERRIDDEN_FUNDER_COST_COLUMN] as string);

      const updatedOverrides =
        newFunderCost !== unOverriddenFunderCost
          ? setFunderCostOverride(funderTag, unOverriddenFunderCost, newFunderCost)
          : clearFunderCostOverride(funderTag);

      dispatch({
        type: ActionType.UPDATE_BUDGET_SETTINGS,
        payload: {
          data: { ...settings, funderCostOverrides: updatedOverrides },
          isDirty: true,
        },
      });
    } else if (editingFunderRecoveryPercentage) {
      const newFunderRecoveryPercentage = parseFloat(newRow[FUNDER_RECOVERY_PERCENTAGE_COLUMN] as string);
      const unOverriddenFunderRecoveryPercentage = parseFloat(
        newRow[UNOVERRIDDEN_FUNDER_RECOVERY_PERCENTAGE_COLUMN] as string,
      );

      const updatedOverrides =
        newFunderRecoveryPercentage !== unOverriddenFunderRecoveryPercentage
          ? setFunderRecoveryPercentageOverride(funderTag, newFunderRecoveryPercentage)
          : clearFunderRecoveryPercentageOverride(funderTag);

      dispatch({
        type: ActionType.UPDATE_BUDGET_SETTINGS,
        payload: {
          data: { ...settings, funderRecoveryOverrides: updatedOverrides },
          isDirty: true,
        },
      });
    }

    return newRow;
  };

  useEffect(() => {
    setFunderCostOverrides(settings.funderCostOverrides);
    setFunderRecoveryOverrides(settings.funderRecoveryOverrides);
  }, [settings]);

  return (
    <DataGridPro
      isCellEditable={(params) => isCellEditable(params.row as GridRowModel)}
      cellModesModel={cellModesModel}
      onCellModesModelChange={handleCellModesModelChange}
      onCellClick={handleCellClick}
      processRowUpdate={processRowUpdate}
      sx={(theme: Theme) => getSummaryStyles(theme)}
      getRowClassName={getRowClassName}
      getCellClassName={getCellClassName}
      treeData
      getTreeDataPath={getTreeDataPath}
      groupingColDef={groupingColDef}
      defaultGroupingExpansionDepth={DEFAULT_EXPANSION_DEPTH}
      autoHeight
      rowHeight={ROW_HEIGHT}
      columnHeaderHeight={HEADER_HEIGHT}
      rows={rows}
      columns={columns}
      disableRowSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      hideFooter
      disableColumnReorder
      disableVirtualization={isTestEnv}
      density="compact"
      loading={funderSummaryTable.length === 0}
    />
  );
}

export default React.memo(FunderSummaryTable);
