import { FormControl, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DatePicker, PickerValidDate } from '@mui/x-date-pickers';

import { dateFormat } from '@/utils';

interface LabelDateProps {
  label: string;
  onChange: (value: PickerValidDate | null) => void;
  value: PickerValidDate | null;
  readOnly?: boolean;
}

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginRight: theme.spacing(2),
  minWidth: 120,
  fontWeight: 500,
}));

const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  flexGrow: 1,
  borderRadius: theme.shape.borderRadius,
  height: 40,
  fontSize: theme.typography.body2.fontSize,
  input: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

function LabelDatePicker({ label, value, onChange, readOnly }: Readonly<LabelDateProps>) {
  return (
    <StyledFormControl>
      <StyledTypography variant="body2">{label}</StyledTypography>
      <StyledDatePicker
        format={dateFormat}
        value={value}
        onChange={(newValue) => onChange(newValue)}
        disabled={readOnly} // readOnly state for MUI Select component still looks clickable. Using disabled for clarity.
      />
    </StyledFormControl>
  );
}

export default LabelDatePicker;
