import { TableChartOutlined } from '@mui/icons-material';
import { Button } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridRowModel } from '@mui/x-data-grid';
import dayjs from 'dayjs';

import DeleteRowIcon from './costings-delete';

import type { Option } from '@/components/material-combobox';
import addDateTimeColumnDefinition from '@/forms/components/table/datetime-column-definition';
import {
  AddExternalColumnDefinition,
  createDropDownColumnDefinition,
} from '@/forms/components/table/dropdown-column-definition';
import addExternalFilterableColumnDefinition from '@/forms/components/table/external-filterable-column-definition';
import addTableColumnDefinition from '@/forms/components/table/table-column-definition';
import { formatValueCell } from '@/forms/utils';
import {
  ColumnDefinition,
  ColumnNames,
  ColumnValueType,
  DropdownColumnDefinition,
  ExternalColumnDefinition,
  FacilitiesAndServicesData,
  GridViewFieldNames,
  TableColumnDefinition,
} from '@/types';

export const getGridColumnDefinitions = ({
  columnDefinitions,
  readOnly,
  onNestedTableButtonClick,
  onDeleteRow,
  onCostingTableButtonClick,
  hasCostingTable,
  facilitiesAndServicesData,
  parentRow,
}: {
  columnDefinitions: ColumnDefinition[];
  readOnly: boolean;
  onNestedTableButtonClick: (column: TableColumnDefinition) => void;
  onDeleteRow: (rowId: string) => void;
  onCostingTableButtonClick: () => void;
  hasCostingTable: boolean;
  facilitiesAndServicesData: FacilitiesAndServicesData[];
  parentRow: GridRowModel;
}): GridColDef[] => {
  function isTimeConsumptionTable() {
    return columnDefinitions.some((column) => column.name === ColumnNames.ConsumptionUnits);
  }

  let columns = columnDefinitions.map((column: ColumnDefinition) => {
    const gridColumnDefinition: GridColDef = {
      field: column.name,
      headerName: column.displayName ?? column.name,
      headerClassName: 'grid-header-theme',
      cellClassName: 'grid-cell-theme',
      sortable: false,
      flex: 1,
      editable: true,
      headerAlign: 'left',
      align: 'left',
    };

    function setNumberColumn(params: GridRenderCellParams, field: string) {
      if (field === ColumnNames.ConsumptionUnits) {
        const unitCost = facilitiesAndServicesData.find(
          (item) => item.id === (parentRow.Item as Option | undefined)?.value,
        )?.unitCost;
        const projectCost = (params.value as number) * (unitCost ?? 0);

        return {
          ...params.row,
          'Consumption Units': params.value as number,
          'Project Cost': projectCost,
        } as GridRenderCellParams;
      }
      return { ...params.row, [field]: params.value as number } as GridRenderCellParams;
    }

    switch (column.$type) {
      case ColumnValueType.number:
        return {
          ...gridColumnDefinition,
          type: 'number',
          editable: column.name === ColumnNames.ProjectCost && isTimeConsumptionTable() ? false : !readOnly,
          renderCell: (params: GridRenderCellParams) =>
            formatValueCell(params.value, { withCurrency: false }, column.name),
          valueSetter: (params: GridRenderCellParams) => setNumberColumn(params, column.name),
        };
      case ColumnValueType.dropdown:
        return createDropDownColumnDefinition(column as DropdownColumnDefinition, {
          ...gridColumnDefinition,
          editable: !readOnly,
        });
      case ColumnValueType.datetime:
        return addDateTimeColumnDefinition({
          baseColDef: gridColumnDefinition,
          readOnly,
        });
      case ColumnValueType.table:
        return addTableColumnDefinition(gridColumnDefinition, () =>
          onNestedTableButtonClick(column as TableColumnDefinition),
        );
      case ColumnValueType.external:
        if ((column as ExternalColumnDefinition).filterable) {
          return addExternalFilterableColumnDefinition(gridColumnDefinition, column, readOnly);
        }
        return AddExternalColumnDefinition(
          { ...gridColumnDefinition, editable: !readOnly },
          column as ExternalColumnDefinition,
        );
      default:
        return { ...gridColumnDefinition, type: 'string', editable: !readOnly };
    }
  });

  if (hasCostingTable) {
    columns = [
      ...columns,
      {
        field: 'Costing Table',
        headerClassName: 'grid-header-theme',
        cellClassName: 'grid-cell-theme',
        sortable: false,
        flex: 1,
        editable: false,
        headerAlign: 'left',
        align: 'left',
        headerName: 'Costing Table',
        renderCell: () => (
          <Button
            data-testid="costing-table-button"
            variant="outlined"
            size="small"
            startIcon={<TableChartOutlined />}
            onClick={onCostingTableButtonClick}
          >
            Costing Table
          </Button>
        ),
      },
    ];
  }

  return [
    ...columns,
    {
      field: GridViewFieldNames.Actions,
      headerName: 'Actions',
      headerClassName: 'grid-header-theme',
      sortable: false,
      editable: false,
      renderCell: (params: GridRenderCellParams) =>
        readOnly || (
          <DeleteRowIcon
            name={(params.row as { Name: string }).Name}
            callback={() => onDeleteRow(params.id.toString())}
          />
        ),
    },
  ] as GridColDef[];
};

export const getColumnDefaultValue = ($type: ColumnValueType, name: string) => {
  if (name === 'Location') {
    return { label: 'Onsite', value: 'Onsite' };
  }
  if ($type === ColumnValueType.text || $type === ColumnValueType.dropdown || $type === ColumnValueType.external) {
    return '';
  }
  if ($type === ColumnValueType.datetime) {
    return dayjs();
  }
  if ($type === ColumnValueType.table) {
    return [];
  }
  return 0;
};
