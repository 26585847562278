import { Save } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Menu, MenuItem, Tab, Tabs, Typography, styled } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { ReactNode, SyntheticEvent, useEffect, useState } from 'react';

import ExportIcon from '@/assets/icons/export.svg';
import SettingsIcon from '@/assets/icons/settings.svg';
import FeedbackSnackbar from '@/components/feedback-snackbar';
import BudgetSettingsDialog from '@/forms/components/budget-settings-dialog';
import Summary from '@/forms/features/summary/summary';
import { ActionType, useBudgetDispatchContext, useBudgetStateContext } from '@forms/contexts/budget-context';
import { FacilitiesAndServicesContextProvider } from '@forms/contexts/facilities-services-context';
import SectionTab from '@forms/features/section/section-tab';
import preawardServiceApi from '@forms/services/preaward-api';

function TabPanel(
  props: Readonly<{
    children: ReactNode;
    index: number;
    value: number;
    prefix: string;
    key?: string;
  }>,
) {
  const { children, value, index, prefix } = props;

  return (
    <Grid role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`${prefix}-${index}`}>
      {value === index && <Box>{children}</Box>}
    </Grid>
  );
}

const ActionButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  border: `1px solid ${theme.palette.grey[300]}`,
  fontWeight: 700,
  borderRadius: theme.shape.borderRadius * 1.5,
  padding: theme.spacing(0.5, 1),
}));

function BudgetTabs(
  props: Readonly<{
    formId: string;
    pageId: string;
    userProjectFormId: string;
    submissionId?: string | null;
    readOnly: boolean;
  }>,
) {
  const [value, setValue] = useState(0);
  const { sections, isDirty, settings } = useBudgetStateContext();
  const dispatch = useBudgetDispatchContext();

  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [exportAnchorEl, setExportAnchorEl] = useState<null | HTMLElement>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const isWithinAnIFrame = window.location !== window.parent.location;

  const handleExportButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setExportAnchorEl(null);
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleExportBudget = async () => {
    const success = await preawardServiceApi.getBudgetExport(
      props.formId,
      props.pageId,
      props.userProjectFormId,
      settings.funderId,
    );
    if (!success) {
      setSnackbarOpen(true);
    }
    handleMenuClose();
  };

  const handleDetailedExport = async () => {
    const success = await preawardServiceApi.getYearlyBudgetExport(
      props.formId,
      props.pageId,
      props.userProjectFormId,
      settings.funderId,
    );
    if (!success) {
      setSnackbarOpen(true);
    }
    handleMenuClose();
  };

  useEffect(() => {
    const getCostingsData = async () => {
      const costingsData =
        props.readOnly && props.submissionId
          ? await preawardServiceApi.getSubmissionCostings(props.formId, props.pageId, props.submissionId)
          : await preawardServiceApi.getCostings(props.formId, props.pageId, props.userProjectFormId);

      dispatch({
        type: ActionType.ADD_SECTIONS,
        payload: costingsData.sections,
      });
      if (costingsData.budgetSettings) {
        dispatch({
          type: ActionType.UPDATE_BUDGET_SETTINGS,
          payload: { data: costingsData.budgetSettings, isDirty: false },
        });
      }
      dispatch({
        type: ActionType.BUDGET_LOADED,
      });
    };

    getCostingsData().catch(() => {});
  }, [dispatch, props.formId, props.pageId, props.readOnly, props.submissionId, props.userProjectFormId]);

  return (
    <>
      <Grid container>
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Tabs value={value} onChange={handleChange}>
              <Tab id="summary" label="Summary" key="0" />
              {sections.map((section, i) => (
                <Tab
                  label={section.displayName ?? section.name}
                  key={section.id}
                  id={`tab-${i + 1}`}
                  aria-controls={`tabpanel-${i}`}
                />
              ))}
            </Tabs>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                {isDirty && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'warning.main',
                      p: 1,
                      fontSize: 12,
                      fontWeight: 600,
                    }}
                  >
                    Changes not saved
                  </Typography>
                )}

                {!isWithinAnIFrame && (
                  <IconButton
                    color="primary"
                    disabled={!isDirty}
                    onClick={() => window.parent.postMessage({ command: 'saveForm', pageId: '1' })}
                  >
                    <Save />
                  </IconButton>
                )}

                <ActionButton
                  startIcon={<img src={ExportIcon} alt="Export" style={{ width: 18, height: 18 }} />}
                  id="export-button"
                  aria-controls="export-menu"
                  aria-haspopup="true"
                  onClick={handleExportButtonClick}
                  endIcon={<ArrowDropDownIcon />}
                >
                  Export
                </ActionButton>

                <Menu
                  id="export-menu"
                  anchorEl={exportAnchorEl}
                  open={Boolean(exportAnchorEl)}
                  onClose={handleMenuClose}
                >
                  <MenuItem onClick={handleExportBudget}>Summary Export</MenuItem>
                  <MenuItem onClick={handleDetailedExport}>Detailed Export</MenuItem>
                </Menu>

                <ActionButton
                  startIcon={<img src={SettingsIcon} alt="Settings" style={{ width: 18, height: 18 }} />}
                  id="settings-button"
                  aria-controls="settings-menu"
                  onClick={() => setSettingsDialogOpen(true)}
                >
                  Budget Settings
                </ActionButton>
              </Box>
            </Box>
            <BudgetSettingsDialog
              open={settingsDialogOpen}
              onClose={() => setSettingsDialogOpen(false)}
              readOnly={props.readOnly}
            />
          </Box>
          <TabPanel value={value} key="0" index={0} prefix="tab">
            <Summary />
          </TabPanel>
          {sections.map((section, i) => (
            <TabPanel value={value} key={section.id} index={i + 1} prefix="tab">
              <Grid container>
                <Grid item xs={12}>
                  <FacilitiesAndServicesContextProvider>
                    <SectionTab
                      sectionId={section.id}
                      sectionName={section.name}
                      sectionDisplayName={section.displayName ?? section.name}
                      categories={section.categories}
                      readOnly={props.readOnly}
                    />
                  </FacilitiesAndServicesContextProvider>
                </Grid>
              </Grid>
            </TabPanel>
          ))}
        </Box>
      </Grid>
      <FeedbackSnackbar
        open={snackbarOpen}
        message="Failed to export budget"
        severity="error"
        onClose={handleSnackbarClose}
      />
    </>
  );
}

export default BudgetTabs;
