import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

import { trackException } from '@/application-insights';
import SettingsIcon from '@/assets/icons/settings.svg';
import LabelDatePicker from '@/components/label-date-picker';
import LabelSelect from '@/components/label-select';
import { ActionType, useBudgetDispatchContext, useBudgetStateContext } from '@/forms/contexts/budget-context';
import preawardServiceApi from '@/forms/services/preaward-api';
import { FunderCostOverride, FunderRecoveryOverride } from '@/types';
import { isoDateFormat } from '@/utils';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius * 2,
  },
}));

const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: 'transparent',
});

const UpdateButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover': { backgroundColor: theme.palette.primary.dark },
}));

interface BudgetSettingsProps {
  open: boolean;
  onClose: () => void;
  readOnly: boolean;
}

function BudgetSettingsDialog({ open, onClose, readOnly }: Readonly<BudgetSettingsProps>) {
  const theme = useTheme();
  const { settings } = useBudgetStateContext();
  const dispatch = useBudgetDispatchContext();
  const [funderId, setFunderId] = useState<string | undefined>(undefined);
  const [pricingSchemeName, setPricingSchemeName] = useState<string | undefined>(undefined);
  const [projectStartDate, setProjectStartDate] = useState<string | undefined>(undefined);
  const [projectEndDate, setProjectEndDate] = useState<string | undefined>(undefined);
  const [funderCostOverrides, setFunderCostOverrides] = useState<FunderCostOverride[]>([]);
  const [funderRecoveryOverrides, setFunderRecoveryOverrides] = useState<FunderRecoveryOverride[]>([]);

  const [funderOptions, setFunderOptions] = useState<{ value: string; label: string }[]>([]);
  const [pricingSchemeOptions, setPricingSchemeOptions] = useState<{ value: string; label: string }[]>([]);

  const handleUpdate = () => {
    dispatch({
      type: ActionType.UPDATE_BUDGET_SETTINGS,
      payload: {
        data: {
          funderId,
          pricingSchemeName,
          projectStartDate,
          projectEndDate,
          funderCostOverrides,
          funderRecoveryOverrides,
        },
        isDirty: true,
      },
    });
    onClose();
  };

  useEffect(() => {
    if (!open) return;
    setFunderId(settings.funderId);
    setPricingSchemeName(settings.pricingSchemeName);
    setProjectStartDate(settings.projectStartDate);
    setProjectEndDate(settings.projectEndDate);
    setFunderCostOverrides(settings.funderCostOverrides);
    setFunderRecoveryOverrides(settings.funderRecoveryOverrides);
  }, [settings, open]);

  useEffect(() => {
    const getFunderOptions = async () => {
      const funders = await preawardServiceApi.getFunders();
      const apiFunderOptions = funders.map((funder) => ({ value: funder.id, label: funder.name }));
      setFunderOptions(apiFunderOptions);
    };

    getFunderOptions().catch(trackException);
  }, []);

  useEffect(() => {
    const getPricingSchemeOptions = async (id: string) => {
      const { pricingSchemes } = await preawardServiceApi.getFunderById(id);
      const apiPricingSchemeOptions = pricingSchemes.map((pricingScheme) => ({
        value: pricingScheme.name,
        label: pricingScheme.name,
      }));
      setPricingSchemeOptions(apiPricingSchemeOptions);
    };

    if (funderId) getPricingSchemeOptions(funderId).catch(trackException);
  }, [funderId]);

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <StyledDialogTitle>
        <Box display="flex" alignItems="center">
          <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, pb: 0.5 }}>
              <img src={SettingsIcon} alt="Import" style={{ width: 18, height: 18 }} />
              <Typography variant="h6">Budget Settings</Typography>
            </Box>
            <Typography variant="body2" color="text.secondary">
              This will impact funder costs, income and funder view of your budget
            </Typography>
          </Box>
          <IconButton onClick={onClose} size="small" aria-label="close">
            <Close />
          </IconButton>
        </Box>
      </StyledDialogTitle>
      <DialogContent sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
        <Typography variant="body2" color="text.secondary">
          FUNDING
        </Typography>
        <Box sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
          <LabelSelect
            label="Funder Name"
            value={(funderOptions.some((o) => o.value === funderId) && funderId) || ''}
            onChange={(e) => setFunderId(e)}
            menuItems={funderOptions}
            readOnly={readOnly}
          />
          <LabelSelect
            label="Pricing Scheme"
            value={(pricingSchemeOptions.some((o) => o.value === pricingSchemeName) && pricingSchemeName) || ''}
            onChange={(e) => setPricingSchemeName(e)}
            menuItems={pricingSchemeOptions}
            readOnly={readOnly}
          />
        </Box>
        <Typography variant="body2" color="text.secondary">
          PROJECT TIMELINE
        </Typography>
        <Box sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>
          <LabelDatePicker
            label="From"
            value={projectStartDate ? dayjs(projectStartDate) : null}
            onChange={(newValue) => {
              if (!newValue || !newValue.isValid()) {
                return;
              }
              setProjectStartDate(newValue.format(isoDateFormat));
            }}
            readOnly={readOnly}
          />
          <LabelDatePicker
            label="To"
            value={projectEndDate ? dayjs(projectEndDate) : null}
            onChange={(newValue) => {
              if (!newValue || !newValue.isValid()) {
                return;
              }
              setProjectEndDate(newValue.format(isoDateFormat));
            }}
            readOnly={readOnly}
          />
        </Box>
      </DialogContent>
      {!readOnly && (
        <DialogActions sx={{ padding: theme.spacing(2) }}>
          <Button onClick={onClose}>Cancel</Button>
          <UpdateButton onClick={handleUpdate} variant="contained">
            Update
          </UpdateButton>
        </DialogActions>
      )}
    </StyledDialog>
  );
}

export default BudgetSettingsDialog;
