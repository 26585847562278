import { GridRowModel } from '@mui/x-data-grid-pro';
import { isEmpty, omit } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { CostEntity } from '@/types';

const locale = process.env.REACT_APP_LOCALE ?? 'en-GB';

function getCurrency(): string | undefined {
  return {
    'en-GB': 'GBP',
    'en-AU': 'AUD',
  }[locale];
}

const getNumberFormatOptions = (withCurrency: boolean, noRounding: boolean): Intl.NumberFormatOptions => {
  const options: Intl.NumberFormatOptions = {
    style: withCurrency ? 'currency' : 'decimal',
    minimumFractionDigits: noRounding ? 1 : 0,
    maximumFractionDigits: noRounding ? 2 : 0,
    useGrouping: true,
    signDisplay: 'never',
  };

  if (withCurrency) {
    options.currency = getCurrency();
  }

  return options;
};

const formatValueCell = (
  value: unknown,
  options: { withCurrency?: boolean } = {},
  columnName: string | undefined = undefined,
) => {
  if (typeof value !== 'number') {
    return String(value);
  }

  const { withCurrency = false } = options;
  const noRounding = columnName?.toLowerCase().includes('effort');

  const formattedValue = new Intl.NumberFormat(locale, getNumberFormatOptions(withCurrency, noRounding ?? false))
    .format(Math.abs(value))
    .replace(/^([\d,.]+)/, '$1 ')
    .replace(/\b([\d,.]+)$/, ' $1');

  return value < 0 ? `(${formattedValue})` : formattedValue;
};

const flattenRows = (
  costEntities: CostEntity[],
  result: GridRowModel[] = [],
  depth = 0,
  parentHierarchy: string[] = [],
  excludedKeys: string[] = [],
): GridRowModel[] => {
  costEntities.forEach((child) => {
    const currentHierarchy = [...parentHierarchy, child.Type];
    const rowData = {
      ...omit(child, excludedKeys),
      id: uuidv4(),
      depth,
      Hierarchy: currentHierarchy,
    };

    result.push(rowData);

    if (child.Children && !isEmpty(child.Children)) {
      flattenRows(child.Children, result, depth + 1, currentHierarchy);
    }
  });

  return result;
};

const costingGridColumnDefinition = {
  headerClassName: 'grid-header-theme',
  cellClassName: 'grid-cell-theme',
  sortable: false,
  flex: 1,
};

const parsePayScale = (payScale?: string): { grade: string; point: string } | null => {
  if (!payScale) return null;
  if (!payScale.includes('-')) return null;

  return { grade: payScale.split('-')[0].trim(), point: payScale.split('-')[1].trim() };
};

export { costingGridColumnDefinition, formatValueCell, flattenRows, parsePayScale };
