import { Grid } from '@mui/material';
import { GridColDef, GridRenderCellParams, GridSingleSelectColDef } from '@mui/x-data-grid-pro';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import MaterialCombobox, { Option, isComboboxOption } from '@/components/material-combobox';
import { useFacilitiesAndServicesContext } from '@/forms/contexts/facilities-services-context';
import preawardServiceApi from '@/forms/services/preaward-api';
import {
  DropdownColumnDefinition,
  ExternalColumnDefinition,
  ExternalDataItem,
  FacilitiesAndServicesData,
} from '@/types';
import { lowerFirstLetter } from '@/utils';

function DropdownRenderCell(cellParams: GridRenderCellParams) {
  return cellParams.value ? (
    <Grid container>{(cellParams.value as Option).label}</Grid>
  ) : (
    <Grid container spacing={0} justifyContent="flex-end" alignItems="center">
      <Grid item xs={10}>
        Select
      </Grid>
      <Grid item xs={2} sx={{ paddingTop: 0.6 }}>
        <ArrowDropDownIcon color="primary" />
      </Grid>
    </Grid>
  );
}

function EditableCombobox({ options, cellParams }: Readonly<{ options: Option[]; cellParams: GridRenderCellParams }>) {
  return (
    <MaterialCombobox
      id={cellParams.field}
      options={options}
      onChange={async (option) => {
        await cellParams.api.setEditCellValue({
          id: cellParams.id,
          field: cellParams.field,
          value: option,
        });
        if (option && cellParams.api.getCellMode(cellParams.id, cellParams.field) === 'edit') {
          cellParams.api.stopCellEditMode({ id: cellParams.id, field: cellParams.field });
        }
      }}
      initialValue={
        isComboboxOption(cellParams.value)
          ? cellParams.value
          : {
              label: options.find((o) => o.value === cellParams.value)?.label as string,
              value: cellParams.value as string,
            }
      }
      autoOpen
    />
  );
}

const createBaseDropDownColumnDefinition = (baseColDef: GridColDef, options: Option[]): GridSingleSelectColDef => ({
  ...baseColDef,
  type: 'singleSelect',
  valueOptions: options,
  renderCell: DropdownRenderCell,
  renderEditCell: (cellParams: GridRenderCellParams) => <EditableCombobox options={options} cellParams={cellParams} />,
});

export function createDropDownColumnDefinition(ddColumn: DropdownColumnDefinition, baseColDef: GridColDef) {
  return createBaseDropDownColumnDefinition(
    baseColDef,
    ddColumn.options.map((option) => ({
      label: option.name,
      value: option.value,
    })),
  );
}

export function AddExternalColumnDefinition(colDef: GridColDef, column: ExternalColumnDefinition) {
  const { setFacilitiesAndServicesData } = useFacilitiesAndServicesContext();
  const externalColumnDefinition = column;
  const { path } = externalColumnDefinition;
  const [options, setOptions] = useState<Option[]>([]);
  const propertyName = lowerFirstLetter(externalColumnDefinition.propertyName);
  const secondaryPropertyName = externalColumnDefinition.secondaryPropertyName
    ? lowerFirstLetter(externalColumnDefinition.secondaryPropertyName)
    : null;

  useEffect(() => {
    const getExternalData = async () => {
      const dataItems = await preawardServiceApi.getExternalData<ExternalDataItem>(path);

      const getLabel = (item: ExternalDataItem) => {
        const primary = item[propertyName];
        const secondary = secondaryPropertyName ? item[secondaryPropertyName] : undefined;

        if (_.isNumber(primary)) {
          return primary;
        }

        const parts = [primary, secondary].filter((val) => _.isString(val));
        return parts.join(' - ');
      };

      const unsortedOptions = dataItems.map((item: ExternalDataItem) => ({
        label: getLabel(item),
        value: item.id ?? getLabel(item),
      }));

      const compareNumber = (a: { label: number }, b: { label: number }) => a.label - b.label;
      const compareString = (a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label);
      let compareFn: typeof compareNumber | typeof compareString;
      if (unsortedOptions.every((option) => _.isNumber(option.value))) {
        compareFn = compareNumber;
      } else if (unsortedOptions.every((option) => _.isString(option.value))) {
        compareFn = compareString;
      } else {
        throw new Error(`Unexpected mixture of string and number type values in external data source ${path}`);
      }

      const sortedOptions = unsortedOptions
        .sort(compareFn as (a: { label: string | number }, b: { label: string | number }) => number)
        .filter((item, index, array) => index === 0 || item.label !== array[index - 1].label);

      setOptions(sortedOptions);

      if (path === '/FacilitiesAndServices') {
        setFacilitiesAndServicesData(dataItems as FacilitiesAndServicesData[]);
      }
    };

    getExternalData().catch(() => {});
  }, [path, propertyName, secondaryPropertyName, setFacilitiesAndServicesData]);

  return createBaseDropDownColumnDefinition(colDef, options);
}
