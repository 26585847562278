import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { GridColDef, GridRowModel, GridValidRowModel, GridValueSetterParams } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { Guid } from 'typescript-guid';

import CostingTableDialog from './costing-table-dialog';
import PeriodsDialog from './periods-dialog';
import CostingsTable from './table';
import { getColumnDefaultValue, getGridColumnDefinitions } from './utils';

import { trackException } from '@/application-insights';
import { Option } from '@/components/material-combobox';
import preawardServiceApi from '@/forms/services/preaward-api';
import {
  Categories,
  ColumnDefinition,
  ColumnNames,
  CostingCategory,
  GridRowModelWithPeriods,
  Paths,
  PeriodType,
  SalaryBand,
  Sections,
  TableColumnDefinition,
} from '@/types';
import { getProjectDurationDefaultPeriod } from '@/utils';
import { ActionType, useBudgetDispatchContext, useBudgetStateContext } from '@forms/contexts/budget-context';
import { parsePayScale } from '@forms/utils';

function SectionTab({
  sectionId,
  sectionName,
  sectionDisplayName,
  categories,
  readOnly,
}: Readonly<{
  sectionId: string;
  sectionName: string;
  sectionDisplayName: string;
  categories: CostingCategory[];
  readOnly: boolean;
}>) {
  const dispatch = useBudgetDispatchContext();
  const [currentRow, setCurrentRow] = useState<GridRowModel>({});
  const [periodsDialogTitle, setPeriodsDialogTitle] = useState<string>('');
  const [periodsDialogColumnDefinitions, setPeriodsDialogColumnDefinitions] = useState<ColumnDefinition[]>([]);
  const [periodsDialogOpen, setPeriodsDialogOpen] = useState(
    Object.fromEntries(categories.map((c) => [c.name, false])),
  );
  const [costingTableDialogOpen, setCostingTableDialogOpen] = useState(
    Object.fromEntries(categories.map((c) => [c.name, false])),
  );
  const [selectedTab, setSelectedTab] = useState(0);
  const [salaryBandsByGrade, setSalaryBandsByGrade] = useState<Record<string, Option>>({});
  const { columnDefinitions, id: categoryId } = categories[selectedTab];
  const { settings } = useBudgetStateContext();
  const haveProjectTimeline = !!settings.projectStartDate && !!settings.projectEndDate;

  function onDeleteRow(rowId: string) {
    dispatch({
      type: ActionType.DELETE_ROW,
      payload: { sectionId, categoryId, rowId },
    });
  }

  function processRowUpdate(newRow: GridRowModel) {
    dispatch({
      type: ActionType.UPDATE_ROW,
      payload: {
        sectionId,
        categoryId,
        existingRowId: newRow.id as string,
        updatedRow: newRow,
      },
    });
    return newRow;
  }

  function addRow(selectedCategoryId: string, newRow: GridRowModel) {
    dispatch({
      type: ActionType.ADD_ROW,
      payload: {
        sectionId,
        categoryId: selectedCategoryId,
        row: newRow,
      },
    });
  }

  function subTabs() {
    return categories?.length > 1 ? (
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={selectedTab}
        exclusive
        onChange={(event: React.SyntheticEvent, newValue: number | null) => {
          if (newValue !== null) {
            setSelectedTab(newValue);
          }
        }}
        aria-label="Category"
      >
        {categories?.map((cat, i) => (
          <ToggleButton key={cat.id} id={`subTab-${i + 1}`} aria-controls={`tabpanel-${i}`} value={i}>
            {cat.name}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    ) : null;
  }

  useEffect(() => {
    const categoryHasPayBandColumn = (c: CostingCategory) =>
      c.columnDefinitions.some((cd) => cd.name === ColumnNames.PayBand);

    const salaryBandByGrades = (b: SalaryBand) =>
      Object.fromEntries(b.grades.map((grade) => [grade, { value: b.id, label: b.name }]));

    const getSalaryBands = async () => {
      const salaryBands = categories.some(categoryHasPayBandColumn)
        ? await preawardServiceApi.getExternalData<SalaryBand>(Paths.SalaryBands)
        : [];

      setSalaryBandsByGrade(
        salaryBands.reduce(
          (acc, b) => ({
            ...acc,
            ...salaryBandByGrades(b),
          }),
          {} as Record<string, Option>,
        ),
      );
    };

    getSalaryBands().catch(trackException);
  }, [categories]);

  const categoryGridColumnDefinitionPayBandOverrides: Record<string, Partial<GridColDef>> = {
    [ColumnNames.StaffMember]: {
      valueSetter: (params: GridValueSetterParams<GridValidRowModel, Option>) => ({
        ...params.row,
        [ColumnNames.StaffMember]: params.value,
        [ColumnNames.PayBand]:
          salaryBandsByGrade[
            parsePayScale((params.row[ColumnNames.PayScale] as Option)?.label as string)?.grade ?? ''
          ] ?? '',
      }),
    },
    [ColumnNames.PayScale]: {
      valueSetter: (params: GridValueSetterParams<GridValidRowModel, { value: string }>) => ({
        ...params.row,
        [ColumnNames.PayScale]: params.value,
        [ColumnNames.PayBand]: salaryBandsByGrade[parsePayScale(params.value.value)?.grade ?? ''] ?? '',
      }),
    },
    [ColumnNames.PayBand]: {
      renderCell: (cellValue) => <Box sx={{ color: 'grey' }}>{(cellValue.value as Option).label}</Box>,
      editable: false,
    },
  };

  const getCategoryGridColumnDefinitions = (category: CostingCategory): GridColDef[] =>
    getGridColumnDefinitions({
      columnDefinitions: category.columnDefinitions,
      readOnly,
      onNestedTableButtonClick: (column: TableColumnDefinition) => {
        if (column.name === ColumnNames.Periods) {
          setPeriodsDialogColumnDefinitions(column.columnDefinitions);
          setPeriodsDialogTitle(column.displayName ?? column.name);
          setPeriodsDialogOpen({ ...periodsDialogOpen, [category.name]: true });
        }
      },
      onDeleteRow,
      onCostingTableButtonClick: () => setCostingTableDialogOpen({ ...costingTableDialogOpen, [category.name]: true }),
      hasCostingTable: !(sectionName === Sections.Partners && category.name === Categories.NonStaff),
      facilitiesAndServicesData: [],
      parentRow: {},
    }).map((d) => ({
      ...d,
      ...(category.columnDefinitions.some((cd) => cd.name === ColumnNames.PayBand) &&
        categoryGridColumnDefinitionPayBandOverrides[d.field]),
    }));

  return (
    <Grid container sx={{ pt: 2 }}>
      <Grid item xs={9}>
        <Stack direction="row" spacing={2}>
          <Box
            sx={{
              display: 'flex', // Enables flexbox
              alignItems: 'center', // Vertical center
              minHeight: '2.5em', // Avoid row changing height when has multiple categories or not
            }}
          >
            <Typography variant="h6">{sectionDisplayName}</Typography>
          </Box>
          {subTabs()}
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Box display="flex" justifyContent="flex-end">
          {!readOnly && (
            <Button
              variant="contained"
              id="addRowButton"
              startIcon={<AddIcon />}
              onClick={() => {
                const periodsColumn = columnDefinitions.find((d) => d.name === ColumnNames.Periods) as
                  | TableColumnDefinition
                  | undefined;

                const rowDefaults = columnDefinitions
                  .filter((d) => d !== periodsColumn)
                  .reduce(
                    (acc, columnDefinition) => {
                      const { name, $type } = columnDefinition;
                      return { ...acc, [name]: getColumnDefaultValue($type, name) };
                    },
                    { id: '1' },
                  );

                const periodSpecificRowDefaults =
                  periodsColumn &&
                  ((haveProjectTimeline
                    ? {
                        PeriodType: PeriodType.ProjectDuration,
                        ProjectDurationPeriod: getProjectDurationDefaultPeriod(periodsColumn.columnDefinitions),
                      }
                    : {
                        PeriodType: PeriodType.Custom,
                        CustomPeriods: [],
                      }) satisfies GridRowModelWithPeriods);

                return addRow(categoryId, {
                  ...rowDefaults,
                  ...periodSpecificRowDefaults,
                  id: Guid.create().toString(),
                });
              }}
            >
              Add
            </Button>
          )}
        </Box>
      </Grid>
      {categories.map((category, i) => (
        <Grid
          key={category.id}
          container
          style={{
            visibility: selectedTab === i ? 'visible' : 'hidden',
            height: selectedTab === i ? 'auto' : 0,
          }}
        >
          <Grid item xs={12} sx={{ mt: 2 }}>
            <CostingsTable
              columns={getCategoryGridColumnDefinitions(category)}
              rows={category.rows}
              onProcessRowUpdate={(newRow) => processRowUpdate(newRow)}
              onRowClick={(row) => setCurrentRow(row)}
              uniqueKey="id"
            />
          </Grid>
          <PeriodsDialog
            sectionId={sectionId}
            categoryId={category.id}
            columns={periodsDialogColumnDefinitions}
            parentRow={currentRow}
            title={periodsDialogTitle}
            open={periodsDialogOpen[category.name]}
            handleClose={() => setPeriodsDialogOpen({ ...periodsDialogOpen, [category.name]: false })}
            readOnly={readOnly}
          />
          <CostingTableDialog
            sectionName={sectionName}
            categoryName={category.name}
            open={costingTableDialogOpen[category.name]}
            handleClose={() => setCostingTableDialogOpen({ ...costingTableDialogOpen, [category.name]: false })}
            parentRow={currentRow}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default SectionTab;
