import { Box } from '@mui/material';
import { GridColDef, GridRowModel } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';

import CostingsTable from './table';
import { getGridColumnDefinitions } from './utils';

import { ColumnDefinition, ColumnNames, GridRowModelWithPeriods, GridViewFieldNames, PeriodType } from '@/types';
import { dateFormat } from '@/utils';
import { ActionType, useBudgetDispatchContext, useBudgetStateContext } from '@forms/contexts/budget-context';
import { useFacilitiesAndServicesContext } from '@forms/contexts/facilities-services-context';

export type PeriodsTableProps = Readonly<{
  sectionId: string;
  categoryId: string;
  columns: ColumnDefinition[];
  parentRow: GridRowModelWithPeriods;
  parentField: string;
  readOnly: boolean;
}>;

function PeriodsTable({
  sectionId,
  categoryId,
  readOnly,
  parentRow,
  parentField,
  columns: columnDefinitions,
}: PeriodsTableProps) {
  const dispatch = useBudgetDispatchContext();
  const { settings } = useBudgetStateContext();
  const { facilitiesAndServicesData } = useFacilitiesAndServicesContext();

  function onDeleteRow(id: string) {
    const currentRow = parentRow;
    currentRow[parentField] = [...(currentRow[parentField] as GridRowModel[]).filter((row) => row.id !== id)];
    dispatch({
      type: ActionType.UPDATE_ROW,
      payload: {
        sectionId,
        categoryId,
        existingRowId: parentRow.id as string,
        updatedRow: currentRow,
      },
    });
  }

  const columns: GridColDef[] = getGridColumnDefinitions({
    columnDefinitions,
    readOnly,
    onNestedTableButtonClick: () => null,
    onDeleteRow,
    onCostingTableButtonClick: () => null,
    hasCostingTable: false,
    facilitiesAndServicesData,
    parentRow,
  });

  if (parentRow.PeriodType === PeriodType.ProjectDuration) {
    const fromColumnIndex = columns.findIndex((c) => c.field === ColumnNames.From);
    columns[fromColumnIndex] = {
      ...columns[fromColumnIndex],
      renderCell: () => (
        <Box sx={{ color: 'grey' }}>{dayjs(settings.projectStartDate as string).format(dateFormat)}</Box>
      ),
      editable: false,
    };

    const toColumnIndex = columns.findIndex((c) => c.field === ColumnNames.To);
    columns[toColumnIndex] = {
      ...columns[toColumnIndex],
      renderCell: () => <Box sx={{ color: 'grey' }}>{dayjs(settings.projectEndDate as string).format(dateFormat)}</Box>,
      editable: false,
    };

    const actionsColumnIndex = columns.findIndex((c) => c.field === GridViewFieldNames.Actions);
    columns[actionsColumnIndex] = { ...columns[actionsColumnIndex], renderCell: undefined };
  }

  function processRowUpdate(newRow: GridRowModel) {
    const currentRow = parentRow;
    currentRow[parentField] = [
      ...(currentRow[parentField] as GridRowModel[]).map((row) => (row.id === newRow.id ? newRow : row)),
    ];

    dispatch({
      type: ActionType.UPDATE_ROW,
      payload: {
        sectionId,
        categoryId,
        existingRowId: parentRow.id as string,
        updatedRow: currentRow,
      },
    });

    return newRow;
  }

  return (
    <CostingsTable
      columns={columns}
      rows={parentRow[parentField] as GridRowModel[]}
      onProcessRowUpdate={(newRow) => processRowUpdate(newRow)}
      uniqueKey="id"
    />
  );
}

export default PeriodsTable;
