import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import SummaryTile, { SummaryTileType } from './summary-tile';

import { useBudgetStateContext } from '@/forms/contexts/budget-context';
import { formatValueCell } from '@/forms/utils';

function SummaryTiles() {
  const { universitySummaryTable } = useBudgetStateContext();
  const [projectCost, setProjectCost] = useState<number>(0);
  const [funderCost, setFunderCost] = useState<number>(0);
  const [income, setIncome] = useState<number>(0);

  const summaryTilesData = [
    { type: SummaryTileType.ProjectCost, value: formatValueCell(projectCost, { withCurrency: true }) },
    { type: SummaryTileType.FunderCost, value: formatValueCell(funderCost, { withCurrency: true }) },
    { type: SummaryTileType.Income, value: formatValueCell(income, { withCurrency: true }) },
    {
      type: SummaryTileType.FECRecovery,
      value: `${((income / projectCost) * 100).toFixed(0)}%`,
    },
  ];

  useEffect(() => {
    if (universitySummaryTable.length > 0) {
      setProjectCost(Number(universitySummaryTable[0]['Project Cost'] ?? 0));
      setFunderCost(Number(universitySummaryTable[0]['Funder Cost'] ?? 0));
      setIncome(Number(universitySummaryTable[0].Income ?? 0));
    }
  }, [universitySummaryTable]);
  return (
    <Grid id="summaryTiles" container spacing={1}>
      {summaryTilesData.map((tile) => (
        <Grid item xs={6} sm={4} md={3} lg={3} key={tile.type}>
          <SummaryTile summaryTileType={tile.type} value={tile.value} />
        </Grid>
      ))}
    </Grid>
  );
}

export default SummaryTiles;
