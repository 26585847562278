import { Box, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import React, { useState } from 'react';

import FunderSummaryTable from '@/forms/features/summary/funder-summary-table';
import UniversitySummaryTable from '@/forms/features/summary/university-summary-table';

function Summary() {
  const [selectedTab, setSelectedTab] = useState('university');

  return (
    <Grid container sx={{ pt: 2 }}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2}>
          <Box
            sx={{
              display: 'flex', // Enables flexbox
              alignItems: 'center', // Vertical center
              minHeight: '2.5em', // Avoid row changing height when has multiple categories or not
            }}
          >
            <Typography variant="h6">Project Budget Breakdown</Typography>
          </Box>
          <ToggleButtonGroup
            size="small"
            color="primary"
            value={selectedTab}
            exclusive
            onChange={(event: React.SyntheticEvent, newValue: string) => {
              if (newValue !== null) {
                setSelectedTab(newValue);
              }
            }}
            aria-label="Summary"
          >
            <ToggleButton aria-controls="university-summary-table" value="university">
              University
            </ToggleButton>
            <ToggleButton aria-controls="funder-summary-table" value="funder">
              Funder
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        {selectedTab === 'university' && (
          <Box id="university-summary-table">
            <UniversitySummaryTable />
          </Box>
        )}
        {selectedTab === 'funder' && (
          <Box id="funder-summary-table">
            <FunderSummaryTable />
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default React.memo(Summary);
