import { Box, Grid } from '@mui/material';

import AdminAppBar from '@/admin/components/admin-appbar';
import ConfigurationTile, { ConfigTile } from '@/admin/components/configuration-tile';
import { UploadType } from '@/admin/services/preaward-api';

const configTiles: ConfigTile[] = [
  {
    title: 'Costing Manager',
    description: 'Create and map cost categories against finance codes across all dimensions',
    url: '/admin/costing-manager',
  },
  {
    title: 'Pricing Manager',
    description:
      'Create and manage funder templates, rules, salary bands, on-costs and more catering to funder requirements',
    url: '/admin/pricing-manager',
  },
  {
    title: 'Facilities & Services',
    description:
      'Define university provided facilities and services to be utilised for a research project thereby accounting it for budget as well',
    url: '/admin/facilities-services',
    state: [
      {
        label: 'Facilities & Services',
        type: UploadType.FacilitiesAndServices,
        stateTitle: 'Get started with facilities & services imports',
        stateButtonTitle: 'Import Facilities & Services',
        stateButtonSubtitle: 'Add facilities & services data from CSV',
      },
    ],
  },
  {
    title: 'Funders Pricing Scheme',
    description: 'Create and manage pricing scheme per funder',
    url: '/admin/funders-pricing-scheme',
    state: [
      {
        label: 'Funders Pricing Scheme',
        type: UploadType.FunderPricingScheme,
        stateTitle: 'Get started with funder pricing scheme',
        stateButtonTitle: 'Import Pricing Scheme',
        stateButtonSubtitle: 'Add Pricing Scheme data from CSV',
      },
    ],
  },
  {
    title: 'Partners',
    description: 'Create and manage different partner organisations here. Used collaboration purposes',
    url: '/admin/partners',
    state: [
      {
        label: 'Partners',
        type: UploadType.Partner,
        stateTitle: 'Get started with partners imports',
        stateButtonTitle: 'Import Partners',
        stateButtonSubtitle: 'Add partners data from CSV',
      },
      {
        label: 'Universities',
        type: UploadType.University,
        stateTitle: 'Get started with universities imports',
        stateButtonTitle: 'Import Universities',
        stateButtonSubtitle: 'Add universities data from CSV',
      },
    ],
  },
  {
    title: 'Organisational Units',
    description: 'Create and manage all departmental units of your organisation and overhead rates.',
    url: '/admin/organisational-units',
    state: [
      {
        label: 'Organisational Units',
        type: UploadType.OrganisationalUnit,
        stateTitle: 'Get started with organisational units imports',
        stateButtonTitle: 'Import Organisational Units',
        stateButtonSubtitle: 'Add organisational units data from CSV',
      },
    ],
  },
  {
    title: 'Staff',
    description:
      'Create and manage staff data, that along with basic details also include pay scales, overhead rates, salary bands etc',
    url: '/admin/staff',
    state: [
      {
        label: 'Staff',
        type: UploadType.Staff,
        stateTitle: 'Get started with staff imports',
        stateButtonTitle: 'Import Staff',
        stateButtonSubtitle: 'Add staff data from CSV',
      },
      {
        label: 'Pay Scales',
        type: UploadType.PayScale,
        stateTitle: 'Get started with pay scale imports',
        stateButtonTitle: 'Import Pay Scales',
        stateButtonSubtitle: 'Add pay scales from CSV',
      },
      {
        label: 'Overhead Rates',
        type: UploadType.OverheadRates,
        stateTitle: 'Get started with overhead rates imports',
        stateButtonTitle: 'Import Overhead Rates',
        stateButtonSubtitle: 'Add overhead rates from CSV',
      },
      {
        label: 'Salary Bands',
        type: UploadType.SalaryBands,
        stateTitle: 'Get started with salary band imports',
        stateButtonTitle: 'Import Salary Bands',
        stateButtonSubtitle: 'Add salary bands from CSV',
      },
    ],
  },
  {
    title: 'Non-staff',
    description:
      'Create and manage non-staff items of your organisation that will be utilised by staffs for any research project',
    url: '/admin/non-staff',
    state: [
      {
        label: 'Non-Staff',
        type: UploadType.NonStaff,
        stateTitle: 'Get started with non-staff imports',
        stateButtonTitle: 'Import Non-Staff',
        stateButtonSubtitle: 'Add non-staff data from CSV',
      },
    ],
  },
  {
    title: 'Inflation Rates',
    description: 'Add and/or manage inflation rates for staffs, non-staff, project roles and more',
    url: '/admin/inflation-rates',
    state: [
      {
        label: 'Staff Type Rates',
        type: UploadType.StaffTypeRates,
        stateTitle: 'Get started with staff type rates imports',
        stateButtonTitle: 'Import Staff Type Rates',
        stateButtonSubtitle: 'Add staff type rates data from CSV',
      },
      {
        label: 'Project Role Rates',
        type: UploadType.ProjectRoleRates,
        stateTitle: 'Get started with project role rates imports',
        stateButtonTitle: 'Import Project Role Rates',
        stateButtonSubtitle: 'Add project role rates data from CSV',
      },
    ],
  },
  {
    title: 'Scholarship Type Rates',
    description: 'Upload scholarship type rates',
    url: '/admin/scholarship-type-rate',
    state: [
      {
        label: 'Scholarship Type Rates',
        type: UploadType.ScholarshipTypeRates,
        stateTitle: 'Get started with scholarship type rates imports',
        stateButtonTitle: 'Import Scholarship Type Rates',
        stateButtonSubtitle: 'Add scholarship type rates data from CSV',
      },
    ],
  },
];

function HomePage() {
  return (
    <Box>
      <AdminAppBar title="Admin Configuration" />
      <Box sx={{ p: 3 }}>
        <Grid container spacing={3}>
          {configTiles.map((tile) => (
            <ConfigurationTile {...tile} key={tile.title} />
          ))}
        </Grid>
      </Box>
    </Box>
  );
}

export default HomePage;
