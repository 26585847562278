import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { GridRowModel, GridValidRowModel } from '@mui/x-data-grid-pro';
import { ReactElement, useEffect, useState } from 'react';
import { Guid } from 'typescript-guid';

import PeriodsTable from './periods-table';
import { getColumnDefaultValue } from './utils';

import { ColumnDefinition, GridRowModelWithPeriods, GridViewFieldNames, PeriodType } from '@/types';
import { getProjectDurationDefaultPeriod } from '@/utils';
import { ActionType, useBudgetDispatchContext, useBudgetStateContext } from '@forms/contexts/budget-context';

const periodFieldNames = {
  [PeriodType.ProjectDuration]: GridViewFieldNames.ProjectDurationPeriod,
  [PeriodType.Custom]: GridViewFieldNames.CustomPeriods,
};

function WithTooltip({ children, tooltip }: { children: ReactElement; tooltip: string | null | undefined }) {
  return tooltip ? (
    <Tooltip title={tooltip}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  );
}

export type PeriodsDialogProps = Readonly<{
  title: string;
  sectionId: string;
  categoryId: string;
  columns: ColumnDefinition[];
  parentRow: GridRowModel;
  open: boolean;
  handleClose: () => void;
  readOnly: boolean;
}>;

function PeriodsDialog(props: PeriodsDialogProps) {
  const { settings } = useBudgetStateContext();
  const haveProjectTimeline = !!settings.projectStartDate && !!settings.projectEndDate;
  const { sectionId, categoryId, columns, open, handleClose, readOnly, parentRow } = props as Omit<
    typeof props,
    'parentRow'
  > & { parentRow: GridRowModelWithPeriods };
  const dispatch = useBudgetDispatchContext();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isCustomPeriodsChecked, setCustomPeriodsChecked] = useState<boolean>(true);
  const fieldName = periodFieldNames[parentRow.PeriodType];

  useEffect(() => {
    setCustomPeriodsChecked(!haveProjectTimeline || parentRow.PeriodType === PeriodType.Custom);
  }, [haveProjectTimeline, parentRow]);

  const defaultRow = columns.reduce(
    (acc, columnDefinition) => {
      const { name, $type } = columnDefinition;
      return { ...acc, [name]: getColumnDefaultValue($type, name) };
    },
    { id: '1' },
  );

  function dispatchUpdateRow(row: GridValidRowModel) {
    dispatch({
      type: ActionType.UPDATE_ROW,
      payload: {
        sectionId,
        categoryId,
        existingRowId: parentRow.id as string,
        updatedRow: row,
      },
    });
  }

  function addNewRow() {
    const newRow = { ...defaultRow, id: Guid.create().toString() };
    parentRow[fieldName] = [...(parentRow[fieldName] as GridRowModel[]), newRow];

    dispatchUpdateRow(parentRow);
  }

  return (
    <Dialog
      id="periods-dialog"
      fullScreen={fullScreen}
      open={open}
      aria-labelledby="periods-dialog-title"
      aria-describedby="periods-dialog-description"
      data-testid={`${sectionId}-${categoryId}-periods-dialog`}
    >
      <DialogTitle>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5" component="span">
              {props.title}
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <WithTooltip
              tooltip={
                haveProjectTimeline
                  ? undefined
                  : 'To add values linked to the project duration, add project timeline details in the Budget Settings screen.'
              }
            >
              <Box marginX={theme.spacing(3)}>
                <Typography color={haveProjectTimeline ? undefined : theme.palette.grey[500]} display="inline">
                  Project duration
                </Typography>
                <Switch
                  checked={isCustomPeriodsChecked}
                  onChange={(event) => {
                    setCustomPeriodsChecked(event.target.checked);
                    parentRow.PeriodType = event.target.checked ? PeriodType.Custom : PeriodType.ProjectDuration;
                    switch (parentRow.PeriodType) {
                      case PeriodType.ProjectDuration:
                        parentRow.ProjectDurationPeriod =
                          parentRow.ProjectDurationPeriod ?? getProjectDurationDefaultPeriod(columns);
                        break;
                      case PeriodType.Custom:
                        parentRow.CustomPeriods =
                          parentRow.CustomPeriods ??
                          (parentRow.ProjectDurationPeriod
                            ? [
                                {
                                  ...parentRow.ProjectDurationPeriod[0],
                                  From: settings.projectStartDate,
                                  To: settings.projectEndDate,
                                },
                              ]
                            : []);
                        break;
                      default:
                        break;
                    }
                    dispatchUpdateRow(parentRow);
                  }}
                  disabled={!haveProjectTimeline}
                />
                <Typography color={haveProjectTimeline ? undefined : theme.palette.grey[500]} display="inline">
                  Custom time periods
                </Typography>
              </Box>
            </WithTooltip>
            <IconButton onClick={handleClose} sx={{ marginInlineEnd: '-1rem' }}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Stack direction="column" spacing={2}>
          {!readOnly && (
            <Box>
              <WithTooltip
                tooltip={
                  isCustomPeriodsChecked
                    ? undefined
                    : 'To add more specific time periods, switch to Custom Time Periods above.'
                }
              >
                <Button
                  variant="text"
                  id="nestedAddRowButton"
                  startIcon={<AddIcon />}
                  onClick={() => addNewRow()}
                  disabled={!isCustomPeriodsChecked}
                >
                  Add Time Period
                </Button>
              </WithTooltip>
            </Box>
          )}
          <PeriodsTable
            sectionId={sectionId}
            categoryId={categoryId}
            columns={columns}
            parentRow={parentRow}
            parentField={fieldName}
            readOnly={readOnly}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PeriodsDialog;
