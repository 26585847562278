import { GridValidRowModel } from '@mui/x-data-grid';
import axios from 'axios';
import dayjs from 'dayjs';

import { trackException } from '@/application-insights';
import { Option } from '@/components/material-combobox';
import { parsePayScale } from '@/forms/utils';
import { FunderSummary, FunderSummaryResponse, FunderSummaryWithPricingSchemes } from '@/funders/types';
import {
  BudgetSettings,
  Categories,
  ColumnNames,
  CostEntityDto,
  CostingSection,
  CostingsDto,
  FacilitiesAndServicesPeriod,
  GridRowModelWithPeriods,
  PeriodType,
  PeriodWithCost,
  PeriodWithEffort,
  Sections,
  StaffModelDto,
  StaffPeriod,
} from '@/types';
import { isoDateFormat } from '@/utils';

const BASE_URL = '/api/preaward';

const preawardApi = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

preawardApi.interceptors.response.use(
  (response) => response,
  (error: Error) => trackException(error),
);

const getCostingsPath = (sectionName: string, categoryName: string): string => {
  const sectionPaths: Record<string, string> = {
    [Sections.StaffCost + Categories.MainCategory]: '/Staff/staff/costings', // To remove once moved to costings template using Staff & NonHrStaff categories
    [Sections.StaffCost + Categories.Staff]: '/Staff/staff/costings',
    [Sections.StaffCost + Categories.NonHrStaff]: '/Staff/non-hr-staff/costings',
    [Sections.StaffCost + Categories.BandedStaff]: '/Staff/banded-staff/costings',
    [Sections.NonStaff + Categories.MainCategory]: '/NonStaff/costings',
    [Sections.Partners + Categories.Staff]: '/Partner/costings',
    [Sections.FacilitiesAndServices + Categories.MainCategory]: '/FacilitiesAndServices/costings',
    [Sections.Students + Categories.Studentship]: '/Students/student/costings',
    [Sections.Students + Categories.OtherStudentCosts]: '/Students/other-student-costs/costings',
  };

  const path = sectionPaths[sectionName + categoryName];
  if (!path) throw new Error(`Costings path not defined for section ${sectionName} and category ${categoryName}`);
  return path;
};

const mapPeriods = <TPeriod extends { From: Date; To: Date }>(
  row: GridValidRowModel,
  settings: BudgetSettings,
  decorator: (period: TPeriod) => Record<string, unknown>,
) => {
  const rowWithPeriods = row as GridRowModelWithPeriods;
  switch (rowWithPeriods.PeriodType) {
    case PeriodType.ProjectDuration:
      return (rowWithPeriods.ProjectDurationPeriod as TPeriod[])?.map((period: TPeriod) => ({
        from: settings.projectStartDate,
        to: settings.projectEndDate,
        ...decorator(period),
      }));
    case PeriodType.Custom:
      return (rowWithPeriods.CustomPeriods as TPeriod[])?.map((period: TPeriod) => ({
        from: dayjs(period.From).format(isoDateFormat),
        to: dayjs(period.To).format(isoDateFormat),
        ...decorator(period),
      }));
    default:
      throw new Error('Unexpected period type');
  }
};

const getOverallPeriodDates = (
  periods: Array<{ from: string | undefined; to: string | undefined }>,
): { overallPeriodFrom: string; overallPeriodTo: string } => {
  const overallPeriodFrom = periods.reduce((min, p) => {
    const date = dayjs(p.from);
    return date.isBefore(dayjs(min)) ? date.format(isoDateFormat) : min;
  }, periods[0]?.from || '');

  const overallPeriodTo = periods.reduce((max, p) => {
    const date = dayjs(p.to);
    return date.isAfter(dayjs(max)) ? date.format(isoDateFormat) : max;
  }, periods[0]?.to || '');

  return { overallPeriodFrom, overallPeriodTo };
};

const buildStaffCostingRequest = (row: GridValidRowModel, settings: BudgetSettings): Record<string, unknown> => {
  const periods = mapPeriods<StaffPeriod>(row, settings, (p) => ({
    effortUnit: p.EffortUnit.value,
    effort: p.Effort,
    location: p.Location.value,
  }));
  const { overallPeriodFrom, overallPeriodTo } = getOverallPeriodDates(periods);

  return {
    staffMemberId: (row?.StaffMember as Option)?.value as string,
    organisationalUnitId: (row['Organisational Unit'] as Option | undefined)?.value,
    costHeading: (row['Cost Heading'] as Option)?.value as string,
    projectRole: (row['Project Role'] as Option)?.label as string,
    grade: parsePayScale((row['Pay Scale'] as Option)?.label as string)?.grade,
    scalePoint: parsePayScale((row['Pay Scale'] as Option)?.label as string)?.point,
    payBandId: (row[ColumnNames.PayBand] as Option | undefined)?.value,
    periods,
    overallPeriodFrom,
    overallPeriodTo,
  };
};

const buildNonHrStaffCostingRequest = (row: GridValidRowModel, settings: BudgetSettings): Record<string, unknown> => {
  const periods = mapPeriods<StaffPeriod>(row, settings, (p) => ({
    effortUnit: p.EffortUnit.value,
    effort: p.Effort,
    location: p.Location.value,
  }));
  const { overallPeriodFrom, overallPeriodTo } = getOverallPeriodDates(periods);

  return {
    fullName: row?.Name as string,
    organisationalUnitId: (row['Organisational Unit'] as Option)?.value,
    costHeading: (row['Cost Heading'] as Option | undefined)?.value,
    projectRole: (row['Project Role'] as Option | undefined)?.label,
    grade: parsePayScale((row['Pay Scale'] as Option)?.label as string)?.grade,
    scalePoint: parsePayScale((row['Pay Scale'] as Option)?.label as string)?.point,
    payBandId: (row[ColumnNames.PayBand] as Option | undefined)?.value,
    pensionScheme: (row['Pension Scheme'] as Option)?.value as string,
    payIncreaseDate: dayjs(row['Pay Increase Date'] as Date).format(isoDateFormat),
    periods,
    overallPeriodFrom,
    overallPeriodTo,
  };
};

const buildStudentCostingRequest = (row: GridValidRowModel, settings: BudgetSettings): Record<string, unknown> => {
  const periods = mapPeriods<PeriodWithEffort>(row, settings, (p) => ({
    effortUnit: p.EffortUnit.value,
    effort: p.Effort,
  }));
  const { overallPeriodFrom, overallPeriodTo } = getOverallPeriodDates(periods);

  return {
    fullName: row?.Name as string,
    scholarshipTypeId: (row['Scholarship Type'] as Option)?.value as string,
    costHeading: (row['Cost Heading'] as Option)?.value as string,
    periods,
    overallPeriodFrom,
    overallPeriodTo,
  };
};

const costingsRequestBuilders: Record<
  string,
  (row: GridValidRowModel, settings: BudgetSettings) => Record<string, unknown>
> = {
  [Sections.StaffCost + Categories.MainCategory]: buildStaffCostingRequest, // To remove once moved to costings template using Staff & NonHrStaff categories
  [Sections.StaffCost + Categories.Staff]: buildStaffCostingRequest,
  [Sections.StaffCost + Categories.NonHrStaff]: buildNonHrStaffCostingRequest,
  [Sections.StaffCost + Categories.BandedStaff]: buildStaffCostingRequest,
  [Sections.NonStaff + Categories.MainCategory]: (row: GridValidRowModel, settings: BudgetSettings) => ({
    name: (row?.Item as Option)?.label as string,
    costHeading: (row['Cost Heading'] as Option)?.value as string,
    periods: mapPeriods<PeriodWithCost>(row, settings, (p) => ({ cost: p.Cost })),
  }),
  [Sections.FacilitiesAndServices + Categories.MainCategory]: (row: GridValidRowModel, settings: BudgetSettings) => ({
    name: (row?.Item as Option)?.label as string,
    costHeading: (row['Cost Heading'] as Option)?.value as string,
    periods: mapPeriods<FacilitiesAndServicesPeriod>(row, settings, (p) => ({
      consumptionUnit: p['Consumption Units'],
      cost: p['Project Cost'],
    })),
  }),
  [Sections.Partners + Categories.Staff]: (row: GridValidRowModel) => ({
    costHeading: (row['Cost Heading'] as Option)?.value as string,
    projectCost: row['Project Cost'] as number,
    funderCost: row['Funder Cost'] as number,
    income: row.Income as number,
  }),
  [Sections.Students + Categories.Studentship]: buildStudentCostingRequest,
  [Sections.Students + Categories.OtherStudentCosts]: (row: GridValidRowModel, settings: BudgetSettings) => ({
    name: (row?.Item as Option)?.label as string,
    costHeading: (row['Cost Heading'] as Option)?.value as string,
    periods: mapPeriods<PeriodWithCost>(row, settings, (p) => ({ cost: p.Cost })),
  }),
};

const downloadFile = (data: Blob, fileName: string) => {
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
};

const preawardServiceApi = {
  saveCostingsInForm: async (
    formId: string,
    pageId: string,
    userProjectFormId: string,
    sections: CostingSection[],
    budgetSettings: BudgetSettings,
  ) => {
    const response = await preawardApi.post('/costings', {
      formId,
      pageId,
      userProjectFormId,
      sections,
      budgetSettings,
    });
    return response.status === 204;
  },

  getCostings: async (formId: string, pageId: string, userProjectFormId: string): Promise<CostingsDto> => {
    const response = await preawardApi.get<CostingsDto>(
      `/costings?formId=${formId}&pageId=${pageId}&userProjectFormId=${userProjectFormId}`,
    );
    return response.data;
  },

  getSubmissionCostings: async (formId: string, pageId: string, submissionId: string): Promise<CostingsDto> => {
    const response = await preawardApi.get<CostingsDto>(
      `/costings/submission?formId=${formId}&pageId=${pageId}&submissionId=${submissionId}`,
    );
    return response.data;
  },

  getFunders: async (pageSize = 10, pageNumber = 1): Promise<FunderSummary[]> => {
    const path = `/Funder?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    const response = await preawardApi.get<FunderSummaryResponse>(path);
    return response.data.funders;
  },

  getFunderById: async (funderId: string): Promise<FunderSummaryWithPricingSchemes> => {
    const path = `/Funder/${funderId}`;
    const response = await preawardApi.get<FunderSummaryWithPricingSchemes>(path);
    return response.data;
  },

  getCostingsTable: async (
    sectionName: string,
    categoryName: string,
    parentRow: GridValidRowModel,
    settings: BudgetSettings,
  ): Promise<CostEntityDto> => {
    const path = getCostingsPath(sectionName, categoryName);
    const requestBody = costingsRequestBuilders[sectionName + categoryName](parentRow, settings);
    requestBody.budgetSettings = settings;
    const response = await preawardApi.post<CostEntityDto>(path, requestBody);
    return response.data;
  },

  getUniversitySummary: async (
    formId: string,
    pageId: string,
    userProjectFormId: string,
    settings: BudgetSettings,
  ): Promise<CostEntityDto> => {
    const response = await preawardApi.post<CostEntityDto>('summary/university', {
      formId,
      pageId,
      userProjectFormId,
      budgetSettings: settings,
    });
    return response.data;
  },

  getFunderSummary: async (
    formId: string,
    pageId: string,
    userProjectFormId: string,
    settings: BudgetSettings,
  ): Promise<CostEntityDto> => {
    const response = await preawardApi.post<CostEntityDto>('summary/funder', {
      formId,
      pageId,
      userProjectFormId,
      budgetSettings: settings,
    });
    return response.data;
  },

  getUniversitySummarySubmission: async (
    formId: string,
    pageId: string,
    submissionId: string,
  ): Promise<CostEntityDto> => {
    const path = `summary/university/submission?formId=${formId}&pageId=${pageId}&submissionId=${submissionId}`;
    const response = await preawardApi.get<CostEntityDto>(path);
    return response.data;
  },

  getFunderSummarySubmission: async (formId: string, pageId: string, submissionId: string): Promise<CostEntityDto> => {
    const path = `summary/funder/submission?formId=${formId}&pageId=${pageId}&submissionId=${submissionId}`;
    const response = await preawardApi.get<CostEntityDto>(path);
    return response.data;
  },

  getExternalData: async <TDataItem>(path: string): Promise<TDataItem[]> => {
    const response = await preawardApi.get<{ data: TDataItem[] }>(path);
    return response.data.data;
  },

  getStaffByNameFilter: async (staffPath: string, name: string): Promise<StaffModelDto> => {
    const response = await preawardApi.get<StaffModelDto>(`${staffPath}?name=${name}`);
    return response.data;
  },

  getBudgetExport: async (
    formId: string,
    pageId: string,
    userProjectFormId: string,
    funderId?: string,
  ): Promise<boolean> => {
    try {
      const response = await preawardApi.get<Blob>('/BudgetExports', {
        params: { formId, pageId, userProjectFormId, funderId },
        responseType: 'blob',
      });
      downloadFile(response.data, `budget_export_${userProjectFormId}.csv`);
      return true;
    } catch (e) {
      return false;
    }
  },

  getYearlyBudgetExport: async (
    formId: string,
    pageId: string,
    userProjectFormId: string,
    funderId?: string,
  ): Promise<boolean> => {
    try {
      const response = await preawardApi.get<Blob>('/BudgetExports/Yearly', {
        params: { formId, pageId, userProjectFormId, funderId },
        responseType: 'blob',
      });
      downloadFile(response.data, `yearly_budget_export_${userProjectFormId}.csv`);
      return true;
    } catch (e) {
      return false;
    }
  },
};

export default preawardServiceApi;
